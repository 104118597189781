import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  DownloadIcon,
  FlipHorizontalIcon,
  PenIcon
} from "lucide-react";
import { Button } from "../../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "../../ui/card";
import { Label } from "../../ui/label";

import { Input } from "../../ui/input";
import { Textarea } from "../../ui/textarea";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "../../ui/dialog";
import { Toggle } from "../../ui/toggle";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../ui/select";
import { ToggleGroup, ToggleGroupItem } from "../../ui/toggle-group";

import React, { useCallback, useRef } from "react";
import { toPng } from "html-to-image";
import assets from "../../../manifest";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas-pro";

function LCSImageGen() {
  const [brand, setBrand] = useState("Test");
  const [layout, setLayout] = useState("right");
  const [heading, setHeading] = useState("Amazing Heading");
  const [action, setAction] = useState("Button");
  const [subheading, setSubheading] = useState("A simple subheading");
  const [brandImage, setBrandImage] = useState("/images/brand/Logo/brand.png");
  const [bgImage, setBgImage] = useState("/images/bg/bg-00001.png");
  const [flipedFG, flipFG] = useState(false);
  const [fgImage, setFgImage] = useState("/images/fg/fg-00003.png");
  const [fgImageRight, setFgRightImage] = useState("");
  const [btnColor, setBtnColor] = useState("bg-teal-600 text-white");
  const [isOpen, setIsOpen] = useState({
    foreground: false,
    background: false
  });
  const handleOpen = type => {
    setIsOpen(prev => ({
      ...prev,
      [type]: true // Dynamically set foreground or background to true
    }));
  };
  const handleClose = () => {
    setIsOpen({ foreground: false, background: false });
  };
  const ref = useRef(null);
  const ref2 = useRef(null);
  const onButtonClick = useCallback(async () => {
    if (ref.current === null) {
      return;
    }
    const canvas = await html2canvas(ref.current);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "download.png", "image/png");
  }, [ref]);

  const onButtonClick2 = useCallback(async () => {
    if (ref2.current === null) {
      return;
    }

    const canvas = await html2canvas(ref2.current);
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "download.png", "image/png");

  }, [ref2]);

  function setLayoutAndRef(layout) {
    setLayout(layout);
  }

  useEffect(() => {
    const firstRef = document.getElementById(`${layout}-1`);
    const secondRef = document.getElementById(`${layout}-2`);
    ref.current = firstRef;
    ref2.current = secondRef;
  }, [layout]);

  return (
    <>
      <header className="flex items-center justify-between p-4 border-b bg-background">
        <div className="flex items-center gap-4">
          <PenIcon className="w-6 h-6" />
          <h1 className="text-xl font-bold">LCS Banner Designer</h1>
        </div>
        <div className="flex items-center gap-4">
          <Button variant="outline" size="sm" onClick={onButtonClick2}>
            <DownloadIcon className="w-4 h-4 mr-2" />
            Download Rounded
          </Button>
          <Button variant="outline" size="sm" onClick={onButtonClick}>
            <DownloadIcon className="w-4 h-4 mr-2" />
            Download Normal
          </Button>
          {/*<Button variant='default' size='sm'>
            <ShareIcon className='w-4 h-4 mr-2' />
            Share
          </Button>*/}
        </div>
      </header>
      <main>
        <section className="grid w-full grid-cols-8 gap-6 p-6">
          <Card className="col-span-3 ">
            <CardHeader>
              <CardTitle>Make Banner</CardTitle>
              <CardDescription>
                Enter details below to make your banner
              </CardDescription>
              <CardContent className="px-0 ">
                <div className="mt-4 space-y-3 ">
                  <div>
                    <Label htmlFor="Layout">Brand</Label>
                    <div className="flex w-full mt-2">
                      <Select
                        onValueChange={val => {
                          setBrandImage(val);
                        }}
                      >
                        <SelectTrigger className="w-[360px]">
                          <SelectValue placeholder="Job Hai" />
                        </SelectTrigger>
                        <SelectContent>
                          {assets.brand.map(b => {
                            return (
                              <SelectItem value={b.link}>{b.name}</SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  <div></div>
                  <Label htmlFor="Layout">Adjust Images</Label>
                  <div className="grid grid-cols-2 gap-3 max-w-[360px]">
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="mb-4"
                          onClick={() => handleOpen("background")}
                        >
                          Select Background
                        </Button>
                      </DialogTrigger>
                      {isOpen?.background ? (
                        <DialogContent
                          className="sm:max-w-[625px]"
                          handleClose={handleClose}
                        >
                          <DialogHeader>
                            <DialogTitle>Select Background Image</DialogTitle>
                          </DialogHeader>
                          <div className="grid grid-cols-4 gap-4 p-1 max-h-98 overflow-y-auto">
                            {assets.bg.map(img => {
                              let link = img.link;

                              return (
                                <img
                                  onClick={() => {
                                    setBgImage(link);
                                    handleClose();
                                  }}
                                  src={link}
                                  className="col-span-1 cursor-pointer "
                                  key={`Background${link}`}
                                />
                              );
                            })}
                          </div>
                        </DialogContent>
                      ) : null}
                    </Dialog>

                    <Dialog>
                      <DialogTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="mb-4"
                          onClick={() => handleOpen("foreground")}
                        >
                          Select Foreground
                        </Button>
                      </DialogTrigger>
                      {isOpen?.foreground ? (
                        <DialogContent
                          className="sm:max-w-[825px]"
                          handleClose={handleClose}
                        >
                          <DialogHeader>
                            <DialogTitle>Select Foreground Image</DialogTitle>
                          </DialogHeader>
                          <div className="grid grid-cols-4 gap-4 p-1 max-h-96 overflow-y-auto">
                            {assets.fg.map(img => {
                              let link = img.link;
                              return (
                                <img
                                  onClick={() => {
                                    setFgImage(link);
                                    handleClose();
                                  }}
                                  src={link}
                                  className="col-span-1 cursor-pointer"
                                  key={`Foreground${link}`}
                                />
                              );
                            })}
                          </div>
                        </DialogContent>
                      ) : null}
                    </Dialog>
                  </div>
                  <div>
                    <Label htmlFor="Layout Alignment">Layout</Label>
                    <div className="flex w-full mt-2">
                      <ToggleGroup
                        value={layout}
                        onValueChange={val => setLayoutAndRef(val)}
                        type="single"
                      >
                        <ToggleGroupItem variant="outline" value="left">
                          <AlignLeftIcon size={16} />
                        </ToggleGroupItem>
                        <ToggleGroupItem variant="outline" value="centre">
                          <AlignCenterIcon size={16} />
                        </ToggleGroupItem>
                        <ToggleGroupItem variant="outline" value="right">
                          <AlignRightIcon size={16} />
                        </ToggleGroupItem>
                      </ToggleGroup>
                    </div>
                  </div>
                  <div>
                    <Label htmlFor="Layout">Flip Foreground Image</Label>
                    <div className="flex w-full mt-2">
                      <Toggle
                        onClick={() => {
                          flipFG(!flipedFG);
                        }}
                        variant="outline"
                      >
                        <FlipHorizontalIcon size={16} />
                      </Toggle>
                    </div>
                  </div>
                  <div className=" max-w-[360px]">
                    <Label htmlFor="Heading">Heading</Label>
                    <Input
                      value={heading}
                      onChange={e => setHeading(e.target.value)}
                      placeholder="Heading"
                    />
                  </div>
                  <div className=" max-w-[360px]">
                    <Label htmlFor="Subheading">Subheading</Label>
                    <Textarea
                      value={subheading}
                      onChange={e => setSubheading(e.target.value)}
                      placeholder="Subheading"
                    />
                  </div>
                  <div className=" max-w-[360px]">
                    <Label htmlFor="Action">Button</Label>
                    <Input
                      value={action}
                      onChange={e => setAction(e.target.value)}
                      placeholder="Apply Now"
                    />
                  </div>

                  <div className=" max-w-[360px]">
                    <Label htmlFor="Button Color">Button Color</Label>
                    <div className="flex items-center gap-2 mt-3">
                      {assets.button.map(bg => {
                        let color = bg.color;

                        return (
                          <div
                            onClick={() => {
                              setBtnColor(color);
                            }}
                            className={`size-8 rounded-full border ${color}`}
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </CardContent>
            </CardHeader>
          </Card>
          <Card className="col-span-5 ">
            <CardHeader>
              <CardTitle>Preview - Normal</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="">
                {layout == "left" ? (
                  <div
                    id={`${layout}-1`}
                    ref={layout == "left" ? ref : null}
                    style={{ backgroundImage: `url('${bgImage}')` }}
                    className={`h-[360px] w-[720px]  border bg-cover flex flex-col py-6 justify-center relative `}
                  >
                    <div className="absolute top-6 left-6 ">
                      <img src={brandImage} className="w-12 " />
                    </div>
                    <div className="absolute bottom-0 right-6 ">
                      <img
                        src={fgImage}
                        className={`w-[300px] ${
                          flipedFG ? "scale-x-[-1]" : null
                        }  `}
                      />
                    </div>
                    <div className="pl-6">
                      <h1 className=" max-w-[480px] text-4xl font-bold text-left">
                        {heading}
                      </h1>
                      <p className="mt-3 text-xl text-left ">{subheading}</p>
                      <div
                        className={`px-6 py-2 mt-4 text-lg ${btnColor} rounded-full w-fit`}
                      >
                        {action}
                      </div>
                    </div>
                  </div>
                ) : layout == "centre" ? (
                  <div
                    id={`${layout}-1`}
                    ref={layout == "centre" ? ref : null}
                    style={{ backgroundImage: `url('${bgImage}')` }}
                    className={`h-[360px] w-[720px] rounded-lg border bg-cover flex flex-col py-6 justify-center items-center relative `}
                  >
                    <div className="absolute top-6 left-6 ">
                      <img src={brandImage} className="w-12 " />
                    </div>

                    <div className="absolute bottom-0 right-6 ">
                      <img src={fgImageRight} className="w-[200px]  " />
                    </div>
                    <div className="absolute bottom-0 left-6 ">
                      <img
                        src={fgImage}
                        className={`w-[200px] ${
                          flipedFG ? "scale-x-[-1]" : null
                        }  `}
                      />
                    </div>
                    <div className="z-50 flex flex-col items-center ">
                      <h1 className=" max-w-[480px] text-4xl font-bold text-center">
                        {heading}
                      </h1>
                      <p className="mt-3 text-xl text-left ">{subheading}</p>
                      <div
                        className={`px-6 py-2 mt-4 text-lg text-white bg-teal-600 ${btnColor} rounded-full w-fit`}
                      >
                        {action}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    id={`${layout}-1`}
                    ref={layout == "right" ? ref : null}
                    style={{ backgroundImage: `url('${bgImage}')` }}
                    className={`h-[360px] w-[720px] rounded-lg border bg-cover flex flex-col py-6 justify-center items-end relative `}
                  >
                    <div className="absolute top-6 right-6 ">
                      <img src={brandImage} className="w-12 " />
                    </div>

                    <div className="absolute bottom-0 left-0 ">
                      <img
                        src={fgImage}
                        className={`w-[300px] ${
                          flipedFG ? "scale-x-[-1]" : null
                        }`}
                      />
                    </div>

                    <div className="flex flex-col items-end pr-6">
                      <h1 className=" max-w-[480px] text-4xl font-bold text-right">
                        {heading}
                      </h1>
                      <p className="mt-3 text-right ">{subheading}</p>
                      <div
                        className={`px-6 py-2 mt-4 text-lg text-white bg-teal-600 ${btnColor} rounded-full w-fit`}
                      >
                        {action}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
            <CardHeader>
              <CardTitle>Preview - Rounded</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="">
                {layout == "left" ? (
                  <div
                    id={`${layout}-2`}

                    ref={layout == "left" ? ref2 : null}
                    style={{ backgroundImage: `url('${bgImage}')` }}
                    className={`h-[360px] w-[720px] rounded-2xl border bg-cover flex flex-col py-6 justify-center relative `}
                  >
                    <div className="absolute top-6 left-6 ">
                      <img src={brandImage} className="w-12 " />
                    </div>
                    <div className="absolute bottom-0 right-6 ">
                      <img
                        src={fgImage}
                        className={`w-[300px] ${
                          flipedFG ? "scale-x-[-1]" : null
                        }  `}
                      />
                    </div>
                    <div className="pl-6">
                      <h1 className=" max-w-[480px] text-4xl font-bold text-left">
                        {heading}
                      </h1>
                      <p className="mt-3 text-xl text-left ">{subheading}</p>
                      <div
                        className={`px-6 py-2 mt-4 text-lg ${btnColor} rounded-full w-fit`}
                      >
                        {action}
                      </div>
                    </div>
                  </div>
                ) : layout == "centre" ? (
                  <div
                    id={`${layout}-2`}

                    ref={layout == "centre" ? ref2 : null}
                    style={{ backgroundImage: `url('${bgImage}')` }}
                    className={`h-[360px] w-[720px] rounded-2xl border bg-cover flex flex-col py-6 justify-center items-center relative `}
                  >
                    <div className="absolute top-6 left-6 ">
                      <img src={brandImage} className="w-12 " />
                    </div>

                    <div className="absolute bottom-0 right-6 ">
                      <img src={fgImageRight} className="w-[200px]  " />
                    </div>
                    <div className="absolute bottom-0 left-6 ">
                      <img
                        src={fgImage}
                        className={`w-[200px] ${
                          flipedFG ? "scale-x-[-1]" : null
                        }  `}
                      />
                    </div>
                    <div className="z-50 flex flex-col items-center ">
                      <h1 className=" max-w-[480px] text-4xl font-bold text-center">
                        {heading}
                      </h1>
                      <p className="mt-3 text-xl text-left ">{subheading}</p>
                      <div
                        className={`px-6 py-2 mt-4 text-lg text-white bg-teal-600 ${btnColor} rounded-full w-fit`}
                      >
                        {action}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    id={`${layout}-2`}

                    ref={layout == "right" ? ref2 : null}
                    style={{ backgroundImage: `url('${bgImage}')` }}
                    className={`h-[360px] w-[720px] rounded-2xl border bg-cover flex flex-col py-6 justify-center items-end relative `}
                  >
                    <div className="absolute top-6 right-6 ">
                      <img src={brandImage} className="w-12 " />
                    </div>

                    <div className="absolute bottom-0 left-0 ">
                      <img
                        src={fgImage}
                        className={`w-[300px] ${
                          flipedFG ? "scale-x-[-1]" : null
                        }`}
                      />
                    </div>

                    <div className="flex flex-col items-end pr-6">
                      <h1 className=" max-w-[480px] text-4xl font-bold text-right">
                        {heading}
                      </h1>
                      <p className="mt-3 text-right ">{subheading}</p>
                      <div
                        className={`px-6 py-2 mt-4 text-lg text-white bg-teal-600 ${btnColor} rounded-full w-fit`}
                      >
                        {action}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </section>
      </main>
    </>
  );
}

export default LCSImageGen;
