import React, { useState } from "react";
import classes from "./lcsCampaignDetails.module.css";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { checkPermission } from "../../../services/utils";
import Label from "../../Common/Form/Label";
import Chips from "../../Common/Chips";
import { updateCampaignStatus } from "../../../services/lcsCampaign";
import useLcsCampaignEditPermission from "../../../zustandService/useLcsCampaignEditPermission";
const statusChips = [
  { id: "ACTIVE", label: "Active" },
  { id: "INACTIVE", label: "Inactive" },
  { id: "TEST", label: "Active For Testing" }
];
const ShowModal = ({
  ctaLabel,
  modalShow,
  closeModal,
  updateStatus,
  error
}) => {
  return (
    <Modal show={modalShow} onHide={closeModal}>
      <Modal.Body>
        <div className="clr">
          <b>
            Are you sure you want to {ctaLabel} this campaign?
            <br />
          </b>
        </div>
        <div className="gap-20" />
        <p>
          <i className="fa-solid fa-triangle-exclamation" /> Please make sure
          all the campaign details and related questions are correct and valid:
        </p>
        {error && <span className="text-danger">{error}</span>}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={updateStatus}
          disabled={error}
        >
          {ctaLabel}
        </button>
        <button className="btn btn-secondary" onClick={closeModal}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const CampaignStatus = ({
  campaignData = {},
  isSideBar = false,
  status,
  setStatus,
  callBack
}) => {
  const {
    start_date = "",
    end_date = "",
    campaign_type_banner = [],
    campaign_post_apply_banner = [],
    createdAt,
    updatedAt,
    last_active_timestamp,
    id,
    is_active,
    is_active_in_screening_flow,
    is_active_for_job_mapping,
    created_by: createdBy = "",
    active_by: activeBy = "",
    modified_by: modifiedBy = ""
  } = campaignData;

  const disabledAccess = !checkPermission("CampaignManagementAdmin");
  const [modalShow, setModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const [error, setError] = useState(null);

  const { updateEditPermission, canEditCampaign } =
    useLcsCampaignEditPermission(state => ({
      updateEditPermission: state.updateEditPermission,
      canEditCampaign: state.canEditCampaign
    }));

  const today = new Date().toISOString().split("T")[0]; // Get today's date in "YYYY-MM-DD" format

  const toggleStatus = (e, fieldName, chipValue) => {
    e.stopPropagation();
    setError(null);
    if (chipValue !== status) {
      setSelectedItem(chipValue);
      setModalShow(true);
    }
  };

  function closeModal(e) {
    e.stopPropagation();
    setModalShow(false);
  }

  function validateField(fieldName, value) {
    switch (fieldName) {
      case "end_date":
        if (value < start_date) {
          setError("Please verify campaign end date");
          return false;
        }
        break;
      case "campaign_type_banner":
        if (is_active && !value.length) {
          setError("Please add dashboard/srp banner");
          return false;
        }
        break;
      case "campaign_post_apply_banner":
        if (is_active_in_screening_flow && !value.length) {
          setError("Please add post apply banner");
          return false;
        }
        break;
      default:
        break;
    }
    return true;
  }

  async function handleUpdateStatus(e) {
    e.stopPropagation();
    let isValid = true;
    isValid &= validateField("end_date", end_date);
    isValid &= validateField("campaign_type_banner", campaign_type_banner);
    isValid &= validateField(
      "campaign_post_apply_banner",
      campaign_post_apply_banner
    );
    if (!isValid) return;
    if (
      !is_active_in_screening_flow &&
      !is_active &&
      !is_active_for_job_mapping
    ) {
      setError("Campaign is not active for any flow");
      return false;
    }
    const newStatus = selectedItem.id;
    try {
      await updateCampaignStatus({ campaignId: id, status: newStatus });
      setStatus(newStatus);
      if (newStatus === "ACTIVE") {
        updateEditPermission(false);
      } else if (!canEditCampaign) {
        updateEditPermission(true);
      }
      setModalShow(false);
      if (callBack) callBack();
    } catch (error) {
      setError(error || "Failed to update campaign status. Please try again.");
    }
  }
  const activeChips = statusChips.filter(item => item.id === status);
  let statusClassName = classes.inactive;
  if (status === "ACTIVE" || status === "TEST")
    statusClassName = classes.active;
  return (
    <>
      {modalShow && (
        <ShowModal
          ctaLabel={selectedItem.label}
          closeModal={closeModal}
          error={error}
          updateStatus={handleUpdateStatus}
          modalShow={modalShow}
        />
      )}
      {isSideBar ? (
        <div
          className={`fullWidth ${
            disabledAccess ? `compaignStatusDisabled` : `compaignStatus`
          }`}
        >
          <Label lable="Campaign Live Status" />
          <div className={classes.statusChip}>
            <Chips
              chips={statusChips}
              activeChips={activeChips}
              onClick={toggleStatus}
              isMulti={false}
              fieldName="campaign_status"
            />
          </div>
          <div className={classes.infoContainer}>
            <div className={classes.item}>Campaign Id: {id}</div>
            <div className={classes.item}>
              Created on: {moment(createdAt).format("DD/MM/YYYY")}
            </div>
            <div className={classes.item}>
              Updated on: {moment(updatedAt).format("DD/MM/YYYY")}
            </div>
            {last_active_timestamp && (
              <div className={classes.item}>
                Last active on:{" "}
                {moment(last_active_timestamp).format("DD/MM/YYYY")}
              </div>
            )}
            <div className={classes.item}>Created by: {createdBy}</div>
            <div className={classes.item}>Last modified by: {modifiedBy}</div>
            <div className={classes.item}>Marked active by: {activeBy}</div>
          </div>
        </div>
      ) : (
        <div className={classes.statusDiv}>
          <span className={statusClassName}>
            {status === "TEST" ? "ACTIVE FOR TESTING" : status}
          </span>
        </div>
      )}
    </>
  );
};

export default CampaignStatus;
