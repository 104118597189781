export const ERROR_MESSAGE = "Something went wrong.";
export const PERSONAL_EMAILS = [
  "@gmail",
  "@yahoo",
  "@outlook",
  "@rediffmail",
  "@hotmail",
  "@live",
  "@ymail",
  "@icloud",
  "@aol",
  "@msn"
];
export const onBoardingOptionsList = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 }
];
export const commonTextFieldLimit = 255;
export const jdTextFieldLimit = 30000;

export const DRIVER_CATERGORY_ID = 999; // 38 in job roles, 999 in custom lead category list
export const GA4_TRACKING_ID_REC = "G-95RRWMBJ2G";

export const MIN_CREDITS = 500;
export const MAX_PAID_CREDITS = 200000;
export const MAX_UNPAID_CREDITS = 200000;
export const GST = 18;
export const MAX_DISCOUNT = 50;
export const MAX_ASSIGN_VOLUME = 99;
export const ONE_JOB_CREDITS = 500;

export const lmsBuckets = {
  1: { value: "New Lead", name: "new" },
  2: { value: "Hot Lead", name: "hot" },
  3: { value: "Warm Lead", name: "warm" },
  4: { value: "Re-attempt leads", name: "cold" },
  5: { value: "Churn Lead", name: "churned" },
  6: { value: "Sales done", name: "sales" },
  7: { value: "Restricted", name: "restricted" }
};
export const minBasicValueReqForPan = 169491;

export const cityCohorts = {
  WEST_CITIES: {
    values: [],
    label: "Select west cities",
    type: "city"
  },
  TOP_10_CITIES: {
    values: [],
    label: "Select top 10 cities",
    type: "city"
  },
  SOUTH_TAMIL: {
    values: [],
    label: "Select south tamil cities",
    type: "city"
  },
  SOUTH_TELUGU: {
    values: [],
    label: "Select south telegu cities",
    type: "city"
  },
  SOUTH_KANNADA: {
    values: [],
    label: "Select south kannada cities",
    type: "city"
  },
  SOUTH_MALAYALAM: {
    values: [],
    label: "Select south malayalam cities",
    type: "city"
  }
};
