import React from "react";
import MetaData from "../../constants/MetaData";
import SuggestedJd from "./SuggestedJd";
import {
  getFormattedValue,
  isEditedFields,
  checkFreeTextValidation
} from "../../services/utils";
import Heading from "../Common/Form/Heading";
import Label from "../Common/Form/Label";
import CustomTextField from "../Common/Form/CustomTextField";
import CustomSelect from "../Common/Form/CustomSelect";
import Chips from "../Common/Chips";
import JobTitleJobsPage from "../Jobs/fields/JobTitleJobsPage";
import SelectCity from "../Common/SelectCity";
import SelectLocality from "../Common/SelectLocality";
import { jdTextFieldLimit } from "../../services/constant";
import JobPostingOtherField from "../JobPostingOtherField";
import PremiumTag from "../Common/PremiumTag";
import NewTag from "../Common/NewTag";

class JobDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBoolean: [],
      activeGender: [],
      activeJobType: [],
      activeShiftType: [],
      activeQualification: [],
      activeEnglishFluency: [],
      showEngOptions: false,
      showRegLanguage: false,
      jdList: [],
      activeJobTypeLocation: [],
      selectedChipView: {
        working_days: {
          activeWorkingDays: {},
          otherWorkingDays: ""
        },
        skills: {
          activeSkills: [],
          otherSkills: ""
        },
        perks: {
          activePerks: [],
          otherPerks: ""
        },
        industry_type: {
          activeIndustryType: [],
          otherIndustry: ""
        },
        deposit_reason: {
          activeDepositReason: [],
          otherDepositReason: ""
        },
        deposit_time: {
          activeDepositTime: []
        }
      }
    };
  }

  componentDidMount() {
    this.setInitialChips();
  }
  componentDidUpdate(prevProps, prevState) {
    const { jobPostFields, saveJobClicked } = this.props;
    if (jobPostFields !== prevProps.jobPostFields) {
      this.setInitialChips();
    }
    if (saveJobClicked !== prevProps.saveJobClicked && saveJobClicked) {
      const { selectedChipView } = this.state;
      const selectedSkills = selectedChipView.skills.activeSkills
        .filter(item => item.type === "free-text")
        .map(item => item.label);
      const selectedPerks = selectedChipView.perks.activePerks
        .filter(item => item.type === "free-text")
        .map(item => item.label);
      let otherSkills = selectedChipView.skills.otherSkills?.trim();
      if (selectedSkills?.includes(otherSkills)) {
        otherSkills = "";
      }
      const otherWorkingDays =
        selectedChipView.working_days.otherWorkingDays?.trim();
      const otherIndustry =
        selectedChipView.industry_type.otherIndustry?.trim();
      let otherPerks = selectedChipView.perks.otherPerks?.trim();
      if (selectedPerks?.includes(otherPerks)) {
        otherPerks = "";
      }

      if (otherWorkingDays) {
        this.updateFieldData("working_days", "Others");
        this.updateFieldData("working_days_text", otherWorkingDays);
      }
      this.updateFieldData(
        "job_skills_text",
        otherSkills ? [...selectedSkills, otherSkills] : selectedSkills
      );
      if (
        otherIndustry ||
        selectedChipView.industry_type.activeIndustryType?.[0] === 9
      ) {
        this.updateFieldData("prior_experience_text", otherIndustry);
        // this code is to make it mandatory
        // this.updateFieldData("prior_experience_type", otherIndustry ? [9] : []);
      }
      this.updateFieldData(
        "company_perks_text",
        otherPerks ? [...selectedPerks, otherPerks] : selectedPerks
      );
    }
  }

  setInitialChips = () => {
    const { data, jobPostFields, jobPostMetaData = {} } = this.props;
    const { jobTypeLocation = [] } = jobPostMetaData;
    const {
      gender_req,
      job_type,
      shift_type,
      working_days,
      is_additional_fee_applicable = false,
      minimum_qualification,
      english_fluency,
      v2_job_skills = [],
      job_perks = [],
      regional_languages,
      prior_experience_type = [],
      prior_experience_text = "",
      job_skills_text = [],
      company_perks_text = [],
      working_days_text = "",
      other_perks,
      deposit_time_id,
      deposit_reason_id,
      reason,
      job_type_location
    } = data;

    const activeGender = gender_req
      ? { id: gender_req, label: gender_req }
      : [];
    const activeJobType = job_type ? { id: job_type, label: job_type } : [];
    const activeShiftType = shift_type
      ? {
          id: shift_type,
          label: shift_type
        }
      : [];

    const workingDays =
      working_days_text || (working_days === "Others" ? "" : working_days);
    const activeWorkingDays = workingDays
      ? {
          id: workingDays,
          label: workingDays,
          type: working_days_text ? "free-text" : ""
        }
      : {};

    const depositReason =
      reason && deposit_reason_id === 6 ? reason : deposit_reason_id;

    const activeDepositReason = depositReason
      ? {
          id: depositReason,
          label: depositReason,
          type: reason && deposit_reason_id === 6 ? "free-text" : ""
        }
      : {};

    const depositTime = deposit_time_id;
    const activeDepositTime = depositTime
      ? {
          id: depositTime,
          label: depositTime,
          type: ""
        }
      : {};

    const isOtherIndustryValidaionPassed = checkFreeTextValidation(
      prior_experience_text
    );

    const otherIndustryList = isOtherIndustryValidaionPassed
      ? [
          {
            label: prior_experience_text,
            panel: "prior_experience_text",
            type: "free-text"
          }
        ]
      : [];

    const activeIndustryType = otherIndustryList.length
      ? otherIndustryList
      : prior_experience_type ?? [];

    const activeBoolean = is_additional_fee_applicable
      ? { value: true, label: "Yes", id: "yes" }
      : { value: false, label: "No", id: "no" };
    const activeQualification = minimum_qualification
      ? {
          id: minimum_qualification,
          label: minimum_qualification
        }
      : [];
    let englishFluency = jobPostFields.english_fluency_job_posting_form.visible
      ? jobPostFields.english_fluency_job_posting_form.options
      : [];

    let activeEnglishFluency = englishFluency.filter(
      item => item.id === english_fluency
    );
    activeEnglishFluency = activeEnglishFluency.length
      ? activeEnglishFluency[0]
      : [];

    let activeSkills = v2_job_skills.filter(n => n);

    const otherSkillList = job_skills_text.map(item => {
      return { label: item, panel: "job_skills_text", type: "free-text" };
    });
    activeSkills = [...activeSkills, ...otherSkillList];

    let otherPerkList = company_perks_text.map(item => {
      return { label: item, panel: "company_perks_text", type: "free-text" };
    });

    const isOtherPerksValidaionPassed = checkFreeTextValidation(other_perks);

    otherPerkList = isOtherPerksValidaionPassed
      ? [{ label: other_perks, panel: "company_perks_text", type: "free-text" }]
      : otherPerkList;

    const activePerkList = [...job_perks, ...otherPerkList];
    const activeJobTypeLocation = job_type_location
      ? jobTypeLocation.find(item => item.id === job_type_location)
      : [];
    this.setState({
      activeGender,
      activeJobType,
      activeShiftType,
      selectedChipView: {
        ...this.state.selectedChipView,
        working_days: {
          activeWorkingDays: activeWorkingDays
        },
        skills: {
          activeSkills: activeSkills
        },
        perks: {
          activePerks: activePerkList,
          otherPerks:
            other_perks &&
            !isOtherPerksValidaionPassed &&
            !company_perks_text.length
              ? other_perks
              : ""
        },
        industry_type: {
          activeIndustryType: activeIndustryType,
          otherIndustry:
            prior_experience_text && !isOtherIndustryValidaionPassed
              ? prior_experience_text
              : ""
        },
        deposit_reason: {
          activeDepositReason
        },
        deposit_time: {
          activeDepositTime
        }
      },
      activeBoolean,
      activeQualification,
      activeEnglishFluency,
      showEngOptions: activeEnglishFluency.id ? true : false,
      showRegLanguage: regional_languages ? regional_languages.length : false,
      activeJobTypeLocation
    });
  };

  updateFieldData = (fieldName, value) => {
    this.props.getFieldData(fieldName, value);
  };

  handleTitleChange = obj => {
    const { category_id = "" } = this.props.data;
    const { id = "", label = "" } = obj;
    const categoryId = id ? id : "";
    if (categoryId && category_id !== categoryId) {
      this.updateFieldData("category_id", categoryId);
    }
    this.updateFieldData("job_title", label);
  };

  handleSuggestedJd = jdList => {
    this.updateFieldData("suggested_jds", jdList);
  };

  handleChange = event => {
    let name = event.target.name;
    let value = getFormattedValue(event.target.value);

    if (name === "minExp" || name === "maxExp") {
      value = getFormattedValue(value, true);
    }
    if (
      name === "minSal" ||
      name === "maxSal" ||
      name === "no_of_openings" ||
      name === "job_contact" ||
      name === "amount"
    ) {
      value = getFormattedValue(value, true, true);
    }
    if (name === "minExp" && value > 72) {
      this.updateFieldData("maxExp", 360);
    } else if (name === "job_city_id") {
      this.updateFieldData("job_locality_id", "");
    }
    if (name === "no_of_openings" && value) {
      value = Number(value);
    } else if (name === "category_id") {
      this.setState({
        selectedChipView: {
          ...this.state.selectedChipView,
          industry_type: {
            activeIndustryType: [],
            otherIndustry: ""
          },
          skills: {
            activeSkills: [],
            otherSkills: ""
          }
        }
      });
      this.updateFieldData("prior_experience_text", "");
    }
    this.updateFieldData(name, value);
  };

  handlePerks = (event, fieldName, item) => {
    this.handleMultiChips(
      item,
      "perks",
      "activePerks",
      "job_perks",
      "company_perks_text",
      "otherPerks"
    );
  };

  handleAssets = (event, fieldName, item) => {
    const { v2_job_assets = [] } = this.props.data;
    let id = item.id;
    let assetList = v2_job_assets;
    let checkItemExist = assetList.filter(item => {
      return item === id;
    });
    if (checkItemExist.length) {
      assetList = assetList.filter(x => x !== id);
    } else {
      assetList.push(id);
    }
    this.updateFieldData(fieldName, assetList);
  };

  handleRegLanguages = (event, fieldName, item) => {
    const { data } = this.props;
    const { regional_languages = [] } = data;
    let id = item.id;
    let regionalLanguages = regional_languages || [];
    let checkItemExist = regionalLanguages.filter(item => {
      return item === id;
    });
    if (checkItemExist.length) {
      regionalLanguages = regionalLanguages.filter(item => item !== id);
    } else {
      if (Number(id) === 9999999999) regionalLanguages = [9999999999];
      else {
        regionalLanguages = regionalLanguages.filter(
          item => item !== 9999999999
        );
        regionalLanguages.push(id);
      }
    }
    this.updateFieldData(fieldName, regionalLanguages);
  };

  handleBooleanChips = (event, fieldName, item) => {
    const { activeBoolean } = this.state;
    let id = event.currentTarget.dataset.id;
    if (id === "no") {
      this.updateFieldData("amount", null);
      this.updateFieldData("reason", null);
    }
    this.setState({
      activeBoolean: activeBoolean.id ? [] : item
    });
    this.updateFieldData(fieldName, activeBoolean.id ? "" : id);
  };

  updateMessage = (msg, flag, isReload = false) => {
    this.props.statusMessage(msg, flag, isReload);
  };

  handleCheckBox = event => {
    const name = event.currentTarget.name;
    const checked = event.target.checked;
    this.updateFieldData(name, checked);
  };

  handleEnglishFrequency = (event, fieldName, item) => {
    const { activeEnglishFluency } = this.state;
    let id = item.id;
    this.updateFieldData(fieldName, activeEnglishFluency.id === id ? "" : id);
    this.setState({
      activeEnglishFluency: activeEnglishFluency.id ? [] : item
    });
  };

  handleGender = (event, fieldName, item) => {
    const { activeGender } = this.state;
    let id = item.id;
    this.updateFieldData(fieldName, activeGender.id === id ? "" : id);
    this.setState({ activeGender: activeGender.id ? [] : item });
  };

  handleWorkLocation = (event, fieldName, item) => {
    const { activeJobTypeLocation } = this.state;
    let id = item.id;
    this.updateFieldData(fieldName, activeJobTypeLocation.id === id ? "" : id);
    this.setState({
      activeJobTypeLocation: item
    });
  };

  handleJobType = (event, fieldName, item) => {
    const { activeJobType } = this.state;
    let id = item.id;
    this.updateFieldData(fieldName, activeJobType.id === id ? "" : id);
    this.setState({ activeJobType: activeJobType.id ? [] : item });
  };

  handleShiftType = (event, fieldName, item) => {
    const { activeShiftType } = this.state;
    let id = item.id;
    this.updateFieldData(fieldName, activeShiftType.id === id ? "" : id);
    this.setState({ activeShiftType: activeShiftType.id ? [] : item });
  };

  handleWorkingDays = (event, fieldName, item) => {
    const { selectedChipView } = this.state;
    const ifEmptyState =
      item.type === "free-text" &&
      item.label === selectedChipView.working_days.activeWorkingDays.label;
    this.setState(
      {
        selectedChipView: {
          ...this.state.selectedChipView,
          working_days: {
            activeWorkingDays: ifEmptyState ? {} : item,
            otherWorkingDays: ""
          }
        }
      },
      () => {
        const { selectedChipView } = this.state;
        this.updateFieldData(
          "working_days",
          selectedChipView.working_days.activeWorkingDays.type === "free-text"
            ? "Others"
            : selectedChipView.working_days.activeWorkingDays.id ?? ""
        );
        this.updateFieldData(
          "working_days_text",
          selectedChipView.working_days.activeWorkingDays.type === "free-text"
            ? selectedChipView.working_days.activeWorkingDays.label
            : ""
        );
      }
    );
  };

  handleQualification = (event, fieldName, item) => {
    const { activeQualification } = this.state;
    let id = item.id;
    this.updateFieldData(fieldName, activeQualification.id === id ? "" : id);
    this.setState({ activeQualification: activeQualification.id ? [] : item });
  };

  handleMultiChips = (
    item,
    type,
    activeType,
    fieldState,
    otherFieldState,
    otherTextState
  ) => {
    const { selectedChipView } = this.state;
    const activeData = selectedChipView[type][activeType];
    let skill_id = item.id;
    let tempArray = activeData;
    let updatedActiveSkills = tempArray.filter(skill => {
      return skill.label !== item.label && skill !== skill_id;
    });
    if (
      updatedActiveSkills.length === selectedChipView[type][activeType].length
    )
      updatedActiveSkills.push(item.type === "free-text" ? item : item.id);
    this.setState(
      {
        selectedChipView: {
          ...this.state.selectedChipView,
          [type]: {
            [activeType]: updatedActiveSkills,
            [otherTextState]: ""
          }
        }
      },
      () => {
        const { selectedChipView } = this.state;
        this.updateFieldData(
          fieldState,
          selectedChipView[type][activeType].filter(
            item => item.type !== "free-text"
          )
        );
        this.updateFieldData(
          otherFieldState,
          selectedChipView[type][activeType]
            .filter(item => item.type === "free-text")
            .map(item => item.label)
        );
      }
    );
  };

  handleSkills = async (event, fieldName, item) => {
    this.handleMultiChips(
      item,
      "skills",
      "activeSkills",
      "v2_job_skills",
      "job_skills_text",
      "otherSkills"
    );
  };

  handleClick = type => {
    this.setState({ [type]: true });
  };

  handleIndustryType = (event, fieldName, item) => {
    const fieldDataName =
      item.type === "free-text" ? "prior_experience_text" : fieldName;
    const { selectedChipView } = this.state;
    let otherIndustry = selectedChipView.industry_type.otherIndustry;
    const { jobPostFields } = this.props;
    const isDeliveryType =
      jobPostFields.prior_experience_type.visible &&
      jobPostFields.prior_experience_type.priority_order;
    const { id, label } = item;
    let tempArray = selectedChipView.industry_type.activeIndustryType;
    const checkItemExist = tempArray.filter(item => {
      return item === id;
    });
    if (isDeliveryType) {
      if (checkItemExist.length) {
        tempArray = selectedChipView.industry_type.activeIndustryType.filter(
          x => x !== id
        );
      } else {
        tempArray.push(id);
      }
    } else {
      const ifEmptyState =
        item.type === "free-text" &&
        item.label ===
          selectedChipView.industry_type.activeIndustryType?.[0]?.label;
      tempArray = ifEmptyState ? [] : item.type === "free-text" ? [item] : [id];
      otherIndustry = "";
    }

    this.setState(
      {
        selectedChipView: {
          ...selectedChipView,
          industry_type: {
            activeIndustryType: tempArray,
            otherIndustry: otherIndustry
          }
        }
      },
      () => {
        const { selectedChipView } = this.state;
        const selectedObj =
          selectedChipView.industry_type.activeIndustryType?.[0];
        this.updateFieldData(
          "prior_experience_text",
          selectedObj?.type === "free-text" ? selectedObj?.label : ""
        );
        this.updateFieldData(
          "prior_experience_type",
          selectedObj?.type === "free-text"
            ? [9]
            : selectedChipView.industry_type.activeIndustryType
        );
      }
    );
    this.updateFieldData(
      fieldDataName,
      item.type === "free-text"
        ? tempArray.filter(item => item.type === "free-text")
        : tempArray
    );
  };

  handleDepositTime = (event, fieldName, item) => {
    const { selectedChipView } = this.state;
    const ifEmptyState =
      item.label === selectedChipView.deposit_time.activeDepositTime.label;
    this.setState(
      {
        selectedChipView: {
          ...this.state.selectedChipView,
          deposit_time: {
            activeDepositTime: ifEmptyState ? {} : item
          }
        }
      },
      () => {
        const { selectedChipView } = this.state;
        this.updateFieldData(
          "deposit_time",
          selectedChipView.deposit_time.activeDepositTime.id ?? ""
        );
      }
    );
  };

  handleDepositReason = (event, fieldName, item) => {
    const { selectedChipView } = this.state;
    const ifEmptyState =
      item.type === "free-text" &&
      item.label === selectedChipView.deposit_reason.activeDepositReason.label;
    this.setState(
      {
        selectedChipView: {
          ...this.state.selectedChipView,
          deposit_reason: {
            activeDepositReason: ifEmptyState ? {} : item,
            otherDepositReason: ""
          }
        }
      },
      () => {
        const { selectedChipView } = this.state;
        this.updateFieldData(
          "deposit_reason",
          selectedChipView.deposit_reason.activeDepositReason.type ===
            "free-text"
            ? "Others"
            : selectedChipView.deposit_reason.activeDepositReason.id ?? ""
        );
        this.updateFieldData(
          "deposit_reason_text",
          selectedChipView.deposit_reason.activeDepositReason.type ===
            "free-text"
            ? selectedChipView.deposit_reason.activeDepositReason.label
            : ""
        );
      }
    );
  };

  updateJdList = jdList => {
    this.setState({ jdList });
  };

  render() {
    const {
      crmMetaData = [],
      editedFields = [],
      jobPostFields,
      data,
      saveJobClicked = false,
      jobPostMetaData
    } = this.props;
    const {
      activeBoolean,
      activeGender,
      activeJobType,
      activeShiftType,
      activeQualification,
      activeEnglishFluency,
      showEngOptions,
      showRegLanguage,
      jdList,
      selectedChipView,
      activeJobTypeLocation
    } = this.state;

    const otherIndustry = selectedChipView.industry_type.otherIndustry;
    const otherPerks = selectedChipView.perks.otherPerks;
    const {
      category_id = "",
      job_title = "",
      regional_languages = [],
      minExp = "",
      maxExp = "",
      are_skills_optional = true,
      is_incentive = false,
      v2_job_assets = [],
      job_description = "",
      job_contact = "",
      call_allowed = false,
      no_of_openings = "",
      is_wfh = false,
      is_contract = false,
      job_start_time = "09:00",
      job_end_time = "18:00",
      minSal = "",
      maxSal = "",
      amount = "",
      suggested_jds = [],
      is_fresher_allowed = false,
      is_target_based,
      is_per_packet_based,
      job_city_id = "",
      job_locality_id = "",
      is_industry_exp,
      jobPlan = "",
      is_new_city_job = false
    } = data;
    const moreLabel = [{ id: "addMore", label: "Add more" }];
    const otherLabel = [{ id: "addOther", label: "Other" }];
    const { categories = [] } = crmMetaData;
    const {
      feeDepositReasonList = [],
      feeDepostTimeList = [],
      jobTypeLocation = []
    } = jobPostMetaData;

    let depositReasonList = [];
    feeDepositReasonList.forEach(item => {
      if (item.reason_label !== "Other")
        depositReasonList.push({
          id: item.reason_id,
          label: item.reason_label
        });
    });

    let depositTimeList = [];
    feeDepostTimeList.forEach(item => {
      if (item.reason_label !== "Other")
        depositTimeList.push({
          id: item.reason_id,
          label: item.reason_label
        });
    });

    if (
      selectedChipView.deposit_reason.activeDepositReason.type === "free-text"
    )
      depositReasonList = [
        ...depositReasonList,
        selectedChipView.deposit_reason.activeDepositReason
      ];

    const jobCategories = categories;
    let perkList = jobPostFields.company_perks.visible
      ? jobPostFields.company_perks.options
      : [];

    const otherPerksList = selectedChipView.perks.activePerks.filter(
      item => item.type === "free-text"
    );
    if (otherPerksList.length) perkList = [...perkList, ...otherPerksList];

    const jobType = jobPostFields.job_type.visible
      ? jobPostFields.job_type.options
      : [];
    let jobTypeList = [];
    jobType.map(item => {
      return jobTypeList.push({ id: item, label: item });
    });

    const workingDays = jobPostFields.working_days.visible
      ? jobPostFields.working_days.options
      : [];
    let workingDaysList = [];
    workingDays.forEach(item => {
      if (item !== "Others")
        workingDaysList.push({
          id: item,
          label: item
        });
    });

    if (selectedChipView.working_days.activeWorkingDays.type === "free-text")
      workingDaysList = [
        ...workingDaysList,
        selectedChipView.working_days.activeWorkingDays
      ];

    const gender = jobPostFields.gender.visible
      ? jobPostFields.gender.options
      : [];

    const opt = ["Work from home", "Work from office", "Field Job"];

    const workLocationOptions = opt?.map(item => {
      return { id: item, label: item };
    });

    let genderList = [];
    gender.map(item => {
      return genderList.push({ id: item, label: item });
    });

    const shiftType = jobPostFields.shift_type.visible
      ? jobPostFields.shift_type.options
      : [];
    let shiftTypeList = [];
    shiftType.map(item => {
      return shiftTypeList.push({ id: item, label: item });
    });

    const minQualification = jobPostFields.minimum_qualification.visible
      ? jobPostFields.minimum_qualification.options
      : [];
    let minQualificationList = [];
    minQualification.map(item => {
      return minQualificationList.push({ id: item, label: item });
    });

    const englishFluency = jobPostFields.english_fluency_job_posting_form
      .visible
      ? jobPostFields.english_fluency_job_posting_form.options
      : [];
    const assetsList =
      jobPostFields.assets_v2 && jobPostFields.assets_v2.visible
        ? jobPostFields.assets_v2.options
        : [];
    let regionalLanguages = jobPostFields.regional_languages.visible
      ? jobPostFields.regional_languages.options
      : [];

    regionalLanguages = [
      ...regionalLanguages,
      { id: 9999999999, label: "None of these" }
    ];

    const isIncentive =
      is_incentive === true || Number(is_incentive) === 1 ? 1 : 0;
    const isFresher = is_fresher_allowed;

    const activeAssets = v2_job_assets || [];
    const activeRegionalLanguages = regional_languages || [];
    let skillList = [];
    skillList = jobCategories.filter(d => d.id === Number(category_id)) || [];
    if (skillList.length) {
      skillList = skillList[0].skills || [];
    }
    const otherSkillList = selectedChipView.skills.activeSkills.filter(
      item => item.type === "free-text"
    );
    if (otherSkillList.length) skillList = [...skillList, ...otherSkillList];

    let expList = MetaData.expList;
    let minExpList = expList.filter(minExp => minExp.value !== 72);
    let tempMinExpList = expList;
    let maxExpList = tempMinExpList.filter(
      item => item.value > minExp && item.value <= 73
    );

    const handleOtherFieldChange = (e, field) => {
      const stateKeyMap = {
        otherSkills: "skills",
        otherWorkingDays: "working_days",
        otherPerks: "perks",
        otherIndustry: "industry_type",
        otherDepositReason: "deposit_reason"
      };
      this.setState({
        selectedChipView: {
          ...this.state.selectedChipView,
          [stateKeyMap[field]]: {
            ...this.state.selectedChipView[stateKeyMap[field]],
            [field]: getFormattedValue(e.target.value)
          }
        }
      });
      this.setState({ [field]: getFormattedValue(e.target.value) });
    };

    let industryTypeList = jobPostFields.prior_experience_type.visible
      ? jobPostFields.prior_experience_type.options
      : [];
    industryTypeList = industryTypeList.filter(item => item.id !== 9);

    const otherIndustryList =
      selectedChipView.industry_type.activeIndustryType.filter(
        item => item.type === "free-text"
      );

    if (otherIndustryList.length)
      industryTypeList = [...industryTypeList, ...otherIndustryList];

    const isDeliveryType =
      jobPostFields.prior_experience_type.visible &&
      jobPostFields.prior_experience_type.priority_order;
    const isIndustryType =
      jobPostFields.prior_experience_type.visible &&
      !jobPostFields.prior_experience_type.priority_order;
    const perkValidationFalied =
      !!otherPerks && !checkFreeTextValidation(otherPerks);

    return (
      <>
        <Heading heading="Job Details">
          <PremiumTag label="PREMIUM" jobPlan={jobPlan} />
          {is_new_city_job ? <NewTag /> : null}
        </Heading>
        <div className="formRow">
          <div className="left margin-bottom-title">
            <Label
              lable="Job Title"
              isEdited={isEditedFields(editedFields, "job_title")}
            />
            <JobTitleJobsPage
              selected={job_title ? job_title : ""}
              placeholder="Search job title"
              onChange={this.handleTitleChange}
            />
          </div>
          <div className="right">
            <Label
              lable="Job Category"
              isEdited={isEditedFields(editedFields, "category_id")}
            />
            <CustomSelect
              name="category_id"
              value={category_id}
              onChange={this.handleChange}
              optionsList={jobCategories}
              itemLabel="title"
              optionValue="id"
              isSelectDisabled={true}
            />
          </div>
        </div>
        {isDeliveryType ? (
          <div className="formRow">
            <div className="fullwidth">
              <Label lable="Delivery Type" />
              <Chips
                chips={industryTypeList}
                activeChips={selectedChipView.industry_type.activeIndustryType}
                onClick={this.handleIndustryType}
                fieldName="prior_experience_type"
              />
            </div>
          </div>
        ) : null}

        <div className="formRow">
          <div className="left">
            <Label
              lable="Job Contact"
              isEdited={isEditedFields(editedFields, "job_contact")}
            />
            <CustomTextField
              name="job_contact"
              value={job_contact}
              onChange={this.handleChange}
              maxLength="10"
            />
            <div className="form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="call_allowed"
                onChange={this.handleCheckBox}
                id={`call_allowed`}
                checked={call_allowed}
              />
              <label className="form-check-label" htmlFor={`call_allowed`}>
                Allow incoming calls
              </label>
            </div>
          </div>
          <div className="right">
            <Label
              lable="Number of Openings"
              isEdited={isEditedFields(editedFields, "no_of_openings")}
            />
            <CustomTextField
              name="no_of_openings"
              value={no_of_openings}
              onChange={this.handleChange}
              maxLength={2}
            />
          </div>
        </div>

        <div className="formRow">
          <div className="left">
            <Label
              lable="Job Type"
              isEdited={isEditedFields(editedFields, "job_type")}
            />
            <Chips
              chips={jobTypeList}
              activeChips={activeJobType}
              onClick={this.handleJobType}
              isMulti={false}
              fieldName="job_type"
              isClose={true}
            />

            {/* {jobPostFields.is_wfh.visible ? (
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="is_wfh"
                  onChange={this.handleCheckBox}
                  id={`is_wfh`}
                  checked={is_wfh}
                />
                <label className="form-check-label" htmlFor={`is_wfh`}>
                  WFH Job
                </label>
              </div>
            ) : null} */}

            {jobPostFields.is_contract.visible ? (
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="is_contract"
                  onChange={this.handleCheckBox}
                  id={`is_contract`}
                  checked={is_contract}
                />
                <label className="form-check-label" htmlFor={`is_contract`}>
                  Contract job
                </label>
              </div>
            ) : null}
          </div>

          {jobPostFields.shift_type.visible ? (
            <div className="right">
              <Label
                lable="Job Shift"
                isEdited={isEditedFields(editedFields, "shift_type")}
              />
              <Chips
                chips={shiftTypeList}
                activeChips={activeShiftType}
                onClick={this.handleShiftType}
                isMulti={false}
                fieldName="shift_type"
                isClose={true}
              />
            </div>
          ) : null}
        </div>
        <div className="formRow">
          <div className="fullWidth">
            <Label
              lable="Work Location Type"
              isEdited={isEditedFields(editedFields, "job_type_location")}
            />
            <Chips
              chips={jobTypeLocation}
              activeChips={[activeJobTypeLocation]}
              onClick={this.handleWorkLocation}
              isMulti={false}
              fieldName="jobTypeLocation"
            />
          </div>
        </div>
        <div className="formRow">
          {jobPostFields.working_days.visible ? (
            <div
              className={
                jobPostFields.job_timings.visible ? "left" : "fullWidth"
              }
            >
              <Label
                lable="Working Days"
                isEdited={isEditedFields(editedFields, "working_days")}
              />
              <Chips
                chips={workingDaysList}
                activeChips={[selectedChipView.working_days.activeWorkingDays]}
                onClick={this.handleWorkingDays}
                isMulti={false}
                fieldName="working_days"
                isClose={false}
              />
              <JobPostingOtherField
                otherChips={workingDaysList.filter(
                  item => item.type === "free-text"
                )}
                initiallyShowTextField={
                  this.props.data?.working_days === "Others" &&
                  !this.props.data?.working_days_text
                }
                saveJobClicked={saveJobClicked}
                allowedChips={1}
                otherChipObj={otherLabel}
                value={selectedChipView.working_days.otherWorkingDays}
                onChange={e => handleOtherFieldChange(e, "otherWorkingDays")}
                variant="outlined"
                placeholder="Eg. 3 working days"
                autoComplete="off"
                isMultiSelect={false}
                panel="working_days_text"
                onChipSelect={({ chipObject, panel }) => {
                  this.handleWorkingDays({}, panel, chipObject);
                }}
              />
            </div>
          ) : null}
          {jobPostFields.job_timings.visible ? (
            <div className="right towColumns">
              <Label lable="Working Hours" />
              <div className="dateItem">
                <CustomTextField
                  value={job_start_time}
                  onChange={this.handleChange}
                  type="time"
                  step="1800"
                  name="job_start_time"
                />
              </div>
              <div className="dateItem jdTo">to</div>
              <div className="dateItem">
                <CustomTextField
                  value={job_end_time}
                  onChange={this.handleChange}
                  type="time"
                  step="1800"
                  name="job_end_time"
                />
              </div>
            </div>
          ) : null}
        </div>

        {jobPostFields.skills.visible ? (
          <div className="formRow">
            <div className="fullWidth">
              <Label
                lable="Skills Required"
                isOptional={are_skills_optional}
                isEdited={isEditedFields(editedFields, "v2_job_skills")}
              />
              <Chips
                chips={skillList}
                activeChips={selectedChipView.skills.activeSkills}
                onClick={this.handleSkills}
                fieldName="v2_job_skills"
              />
              <JobPostingOtherField
                saveJobClicked={saveJobClicked}
                otherChips={otherSkillList}
                allowedChips={5}
                otherChipObj={moreLabel}
                value={selectedChipView.skills.otherSkills}
                onChange={e => handleOtherFieldChange(e, "otherSkills")}
                variant="outlined"
                placeholder="Eg. Email Writing, MS Excel, etc"
                autoComplete="off"
                isMultiSelect={true}
                panel="job_skills_text"
                onChipSelect={async ({ chipObject, panel }) => {
                  this.handleSkills({}, panel, chipObject);
                }}
              />
            </div>
          </div>
        ) : null}

        <div className="formRow">
          <div className="left">
            <Label
              lable="Job Description"
              isEdited={isEditedFields(editedFields, "job_description")}
            />
            {category_id ? (
              <SuggestedJd
                categoryId={Number(category_id)}
                selectedJD={suggested_jds}
                handleSuggestedJd={this.handleSuggestedJd}
                updateJdList={this.updateJdList}
                cityId={job_city_id}
              />
            ) : null}
            {!jdList.length ? (
              <CustomTextField
                value={job_description}
                onChange={this.handleChange}
                name="job_description"
                textArea={true}
                rows="4"
                textAreaMaxLength={jdTextFieldLimit}
              />
            ) : null}
          </div>
          {jdList.length ? (
            <div className="right" style={{ marginTop: "32px" }}>
              <CustomTextField
                value={job_description}
                onChange={this.handleChange}
                name="job_description"
                textArea={true}
                rows="4"
                textAreaMaxLength={jdTextFieldLimit}
              />
            </div>
          ) : null}
        </div>

        {jobPostFields.assets_v2 && jobPostFields.assets_v2.visible ? (
          <div className="formRow">
            <div className="fullwidth">
              <Label
                lable="Required for this Job"
                isOptional={true}
                isEdited={isEditedFields(editedFields, "assets")}
              />
              <Chips
                chips={assetsList}
                activeChips={activeAssets}
                onClick={this.handleAssets}
                fieldName="v2_job_assets"
              />
            </div>
          </div>
        ) : null}
        <div className="formRow">
          <div className="left towColumns">
            <Label
              lable="Experience Required"
              isEdited={
                isEditedFields(editedFields, "minExp") ||
                isEditedFields(editedFields, "maxExp")
              }
            />
            <>
              {!isFresher ? (
                <>
                  <div className="dateItem">
                    <CustomSelect
                      name="minExp"
                      value={minExp}
                      onChange={this.handleChange}
                      optionsList={minExpList}
                    />
                  </div>
                  {minExp < 72 ? (
                    <>
                      <div className="dateItem jdTo">to</div>
                      <div className="dateItem">
                        <CustomSelect
                          name="maxExp"
                          value={maxExp}
                          onChange={this.handleChange}
                          optionsList={maxExpList}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </>
            <div className="form-check-inline fullWidth">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_fresher_allowed"
                onChange={this.handleCheckBox}
                id={`is_fresher_allowed`}
                checked={isFresher}
              />
              <label
                className="form-check-label"
                htmlFor={`is_fresher_allowed`}
              >
                Only freshers should apply/call
              </label>
            </div>
          </div>
          <div className="right towColumns">
            <Label
              lable="Salary"
              isEdited={
                isEditedFields(editedFields, "minSal") ||
                isEditedFields(editedFields, "maxSal")
              }
            />
            <div className="dateItem">
              <CustomTextField
                value={minSal}
                onChange={this.handleChange}
                name="minSal"
                maxLength="5"
                startAdornment={<span className="rupee">&#8377;</span>}
              />
            </div>
            <div className="dateItem jdTo">to</div>
            <div className="dateItem">
              <CustomTextField
                value={maxSal}
                onChange={this.handleChange}
                name="maxSal"
                maxLength="5"
                startAdornment={<span className="rupee">&#8377;</span>}
              />
            </div>

            {jobPostFields.plus_incentives.visible ? (
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="is_incentive"
                  onChange={this.handleCheckBox}
                  id={`is_incentive`}
                  checked={isIncentive}
                />
                <label className="form-check-label" htmlFor={`is_incentive`}>
                  Plus more Incentives
                </label>
              </div>
            ) : null}
            {jobPostFields.is_per_packet_based.visible ? (
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="is_per_packet_based"
                  onChange={this.handleCheckBox}
                  id={`is_per_packet_based`}
                  checked={is_per_packet_based ? true : false}
                />
                <label
                  className="form-check-label"
                  htmlFor={`is_per_packet_based`}
                >
                  Salary is on Per- Packet basis
                </label>
              </div>
            ) : null}

            {jobPostFields.is_target_based.visible ? (
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="is_target_based"
                  onChange={this.handleCheckBox}
                  id={`is_target_based`}
                  checked={is_target_based ? true : false}
                />
                <label className="form-check-label" htmlFor={`is_target_based`}>
                  This includes target based incentives
                </label>
              </div>
            ) : null}
          </div>
        </div>
        <div className="formRow">
          <div className="fullwidth">
            <Label lable="Job Benefits" isOptional={true} />
            <Chips
              chips={perkList}
              activeChips={selectedChipView.perks.activePerks}
              onClick={this.handlePerks}
              fieldName="job_perks"
            />
            <JobPostingOtherField
              saveJobClicked={saveJobClicked}
              initiallyShowTextField={perkValidationFalied}
              otherChips={otherPerksList}
              allowedChips={5}
              otherChipObj={moreLabel}
              value={otherPerks}
              onChange={e => handleOtherFieldChange(e, "otherPerks")}
              variant="outlined"
              placeholder="e.g Breakfast, gym, childcare, etc"
              autoComplete="off"
              isMultiSelect={true}
              panel="company_perks_text"
              onChipSelect={({ chipObject, panel }) => {
                this.handlePerks({}, panel, chipObject);
              }}
            />
          </div>
        </div>
        {isIndustryType ? (
          <div className="formRow">
            <div className="fullwidth">
              <Label lable={jobPostFields.prior_experience_type.type_label} />
              <Chips
                chips={industryTypeList}
                activeChips={selectedChipView.industry_type.activeIndustryType}
                onClick={this.handleIndustryType}
                fieldName="prior_experience_type"
                isMulti={false}
              />
              <JobPostingOtherField
                saveJobClicked={saveJobClicked}
                otherChips={otherIndustryList}
                allowedChips={1}
                otherChipObj={otherLabel}
                value={otherIndustry}
                initiallyShowTextField={
                  !!otherIndustry && !checkFreeTextValidation(otherIndustry)
                }
                onChange={e => handleOtherFieldChange(e, "otherIndustry")}
                variant="outlined"
                placeholder="Automobile, Software/IT/Software Services"
                autoComplete="off"
                isMultiSelect={false}
                panel="prior_experience_text"
                onChipSelect={({ chipObject, panel }) => {
                  this.handleIndustryType({}, panel, chipObject);
                }}
              />
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="is_industry_exp"
                  onChange={this.handleCheckBox}
                  id={`is_industry_exp`}
                  checked={is_industry_exp ? true : false}
                />
                <label className="form-check-label" htmlFor={`is_industry_exp`}>
                  I want candidates to have experience in this specific Industry
                </label>
              </div>
            </div>
          </div>
        ) : null}
        <div className="formRow">
          <div className="left">
            <Label
              lable="Gender Required"
              isEdited={isEditedFields(editedFields, "gender_req")}
            />
            <Chips
              chips={genderList}
              activeChips={activeGender}
              onClick={this.handleGender}
              isMulti={false}
              fieldName="gender_req"
              isClose={true}
            />
          </div>
          {jobPostFields.minimum_qualification.visible ? (
            <div className="right">
              <Label
                lable="Minimum Qualification Required"
                isEdited={isEditedFields(editedFields, "minimum_qualification")}
              />
              <Chips
                chips={minQualificationList}
                activeChips={activeQualification}
                onClick={this.handleQualification}
                isMulti={false}
                fieldName="minimum_qualification"
                isClose={true}
              />
            </div>
          ) : null}
        </div>
        {jobPostFields.english_fluency_job_posting_form.visible ? (
          <div className="formRow">
            <div className="fullwidth">
              <Label
                lable="Want candidates with good English?"
                isOptional={true}
                isEdited={isEditedFields(editedFields, "english_fluency")}
              />
              {showEngOptions ? (
                <Chips
                  chips={englishFluency}
                  activeChips={activeEnglishFluency}
                  onClick={this.handleEnglishFrequency}
                  isMulti={false}
                  fieldName="english_fluency"
                  isClose={true}
                />
              ) : (
                <span
                  className="addEnglish"
                  onClick={() => this.handleClick("showEngOptions")}
                >
                  <img
                    src="/images/add-more.svg"
                    alt="Add"
                    width="12"
                    height="12"
                  />
                  Add English Requirement
                </span>
              )}
            </div>
          </div>
        ) : null}

        {jobPostFields.regional_languages.visible ? (
          <div className="formRow">
            <div className="fullwidth">
              <Label lable="Regional Languages" isOptional={true} />
              {showRegLanguage ? (
                <Chips
                  chips={regionalLanguages}
                  activeChips={activeRegionalLanguages}
                  onClick={this.handleRegLanguages}
                  fieldName="regional_languages"
                  isDisabled={activeRegionalLanguages.length === 2}
                />
              ) : (
                <span
                  className="addEnglish"
                  onClick={() => this.handleClick("showRegLanguage")}
                >
                  <img
                    src="/images/add-more.svg"
                    alt="Add"
                    width="12"
                    height="12"
                  />
                  Add Regional Languages
                </span>
              )}
            </div>
          </div>
        ) : null}

        {jobPostFields.is_additional_fee_applicable.visible && (
          <>
            <div className="formRow">
              <div className="fullwidth">
                <Label lable="Is candidate required to pay any fee (e.g. uniform charges, kit, etc.)?" />
                <Chips
                  chips={MetaData.booleanOptions}
                  activeChips={activeBoolean}
                  onClick={this.handleBooleanChips}
                  isClose={true}
                  fieldName="isAadditionalFeeApplicable"
                  isMulti={false}
                />
              </div>
            </div>
            {activeBoolean.id === "yes" ? (
              <div className="fullwidth">
                <div className="formRow">
                  <div className="left">
                    <Label
                      lable="Amount"
                      isEdited={isEditedFields(editedFields, "amount")}
                    />
                    <CustomTextField
                      name="amount"
                      value={amount}
                      onChange={this.handleChange}
                      maxLength="5"
                      placeholder="e.g. 100"
                    />
                  </div>
                  <div className="right">
                    <Label
                      lable="Why are you asking for this deposit money?"
                      isEdited={isEditedFields(editedFields, "deposit_reason")}
                    />
                    <Chips
                      chips={depositReasonList}
                      activeChips={[
                        selectedChipView.deposit_reason.activeDepositReason
                      ]}
                      onClick={this.handleDepositReason}
                      isMulti={false}
                      fieldName="deposit_reason"
                      isClose={false}
                    />
                    <JobPostingOtherField
                      otherChips={depositReasonList.filter(
                        item => item.type === "free-text"
                      )}
                      initiallyShowTextField={
                        this.props.data?.deposit_reason === "Others" &&
                        !this.props.data?.deposit_reason_text
                      }
                      saveJobClicked={saveJobClicked}
                      allowedChips={1}
                      otherChipObj={otherLabel}
                      value={selectedChipView.deposit_reason.otherDepositReason}
                      onChange={e =>
                        handleOtherFieldChange(e, "otherDepositReason")
                      }
                      variant="outlined"
                      placeholder="Eg. Registration charges, Kit charges, etc."
                      autoComplete="off"
                      isMultiSelect={false}
                      panel="deposit_reason_text"
                      onChipSelect={({ chipObject, panel }) => {
                        this.handleDepositReason({}, panel, chipObject);
                      }}
                    />
                  </div>
                </div>
                <div className="formRow">
                  <div className="left">
                    <Label
                      lable="When this amount will be taken from the job seeker?"
                      isEdited={isEditedFields(editedFields, "deposit_time")}
                    />
                    <Chips
                      chips={depositTimeList}
                      activeChips={[
                        selectedChipView.deposit_time.activeDepositTime
                      ]}
                      onClick={this.handleDepositTime}
                      isMulti={false}
                      fieldName="deposit_time"
                      isClose={false}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="formRow">
              <div className="left">
                <Label
                  lable="Job City"
                  isEdited={isEditedFields(editedFields, "job_city_id")}
                />
                <div className="selectBox">
                  <SelectCity
                    filedName={"job_city_id"}
                    value={job_city_id}
                    onChange={this.handleChange}
                    customClass="select fullWidth"
                  />
                </div>
              </div>
              <div className="right">
                <Label
                  lable="Job Locality"
                  isEdited={isEditedFields(editedFields, "job_locality_id")}
                />
                <SelectLocality
                  filedName={"job_locality_id"}
                  value={job_locality_id}
                  cityId={job_city_id}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default JobDetails;
