const working_days = [
  "5 days working",
  "6 days working",
  "7 days working",
  "Others"
];

const job_type = ["Full Time", "Part Time", "Work from home", "Contract"];

const gender = ["Male", "Female"];

const minimum_qualification = [
  "Any",
  "10th Pass",
  "12th Pass",
  "Diploma",
  "Graduate",
  "Post Graduate"
];

const english_fluency = ["NA", "Average", "Good", "Speaks Fluently"];
const fluency_english_user = ["Average", "Good", "Fluent"];

const qualifications = [
  { id: "1", label: "Any" },
  { id: "2", label: "10th Pass" },
  { id: "3", label: "12th Pass" },
  { id: "4", label: "Diploma" },
  { id: "5", label: "Graduate" },
  { id: "6", label: "Post Graduate" }
];
const company_type = [
  "Private",
  "Public Limited",
  "Government",
  "Manpower",
  "Others"
];

const jobStatus = [
  { label: "Enabled", id: "ENABLED", title: "On" },
  { label: "Disabled", id: "DISABLED", title: "Off" }
];

const call_allowed = [
  { id: "1", label: "Yes" },
  { id: "0", label: "No" }
];

// const cleaning_status = ["IN_PROGRESS", "COMPLETED"]

const job_timing = ["Day", "Night", "Rotational"];

const job_timing_hours = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23"
];

const job_timing_minutes = ["00", "15", "30", "45"];

const job_timing_minutes_new = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59"
];

const interview_type = ["SCHEDULE_INTERVIEW", "WALKIN", "TELEPHONIC"];

const interviewType = [
  { id: "SCHEDULE_INTERVIEW", label: "In-person" },
  { id: "TELEPHONIC", label: "Telephonic" }
];

const recruiter_consultant = [
  { value: "COMPANY", label: "Your own company" },
  { value: "CONSULTANT", label: "Your client's company" },
  { value: "BOTH", label: "Both" }
];

const booleanOptions = [
  { value: true, label: "Yes", id: "yes" },
  { value: false, label: "No", id: "no" }
];

const CRM_CLEANING_STATUS = [
  { value: "NEW", label: "Cleaning Status - New" },
  { value: "CLEANED", label: "Cleaned" },
  { value: "IN_PROGRESS", label: "In Progress" },
  { value: "REJECTED - EXPIRED", label: "Rejected - Expired" },
  { value: "REJECTED - OTHER ROLE", label: "Rejected - Other Role" },
  { value: "REJECTED - OTHER", label: "Rejected - Other" },
  { value: "REJECTED - OTHER_CITY", label: "Rejected - Other City" },
  { value: "REJECTED - DUPLICATE", label: "Rejected - Duplicate" },
  { value: "REJECTED - JOB_SEEKER", label: "Rejected - Job Seeker" },
  { value: "REJECTED - CONSULTANT", label: "Rejected - Consultant" },
  {
    value: "REJECTED - GRAYLIST/BLACKLIST",
    label: "Rejected - Graylist/Blacklist"
  },
  {
    value: "REJECTED – ENTITY DOC NOT RECEIVED",
    label: "Rejected – Entity Doc Not Received"
  }
];

const CRM_CALLING_STATUS = [
  { value: "NEW", label: "Calling Status - New" },
  { value: "READY_FOR_QA", label: "Ready for QA" },
  { value: "EMAIL_SENT", label: "Email Sent" },
  {
    value: "REJECTED - EMAIL_NOT_VERIFIED",
    label: "Rejected - Email Not Verified"
  },
  {
    value: "REJECTED - DOCUMENT_NOT_VERIFIED",
    label: "Rejected - Document Not Verified"
  },
  {
    value: "REJECTED - EMAIL_AND_DOCUMENT_NOT_VERIFIED",
    label: "Rejected - Email And Document Not Verified"
  },
  { value: "REJECTED - DENIED", label: "Rejected - Denied" },
  { value: "REJECTED - EXPIRED", label: "Rejected - Expired" },
  { value: "NOT_CONNECTED - 1", label: "Not Connected 1" },
  { value: "NOT_CONNECTED - 2", label: "Not Connected 2" },
  {
    value: "REJECTED - NOT_CONNECTED_3",
    label: "Rejected - Not Connected 3"
  },
  {
    value: "AUTO_REJECTED_DOC_NOT_UPLOADED",
    label: "Auto Rejected – Doc Not Uploaded"
  }
];

const CRM_QA_STATUS = [
  { value: "NEW", label: "QA Status - New" },
  { value: "GO_LIVE", label: "Go Live" },
  { value: "REJECTED", label: "Rejected" }
];

const user_status = ["Unverified", "Verified", "Blocked", "Deactivated"];
const shift_type = ["DAY", "NIGHT", "ROTATIONAL", "FLEXIBLE"];

const sourced_from = [
  "Apna Job",
  "Aasaan Jobs",
  "Careesma",
  "Click India",
  "Facebook",
  "Freshersworld",
  "Google",
  "Just Dial",
  "Indeed",
  "Indiamart",
  "Kaam 24",
  "Letsryl",
  "Linkedin",
  "Monster",
  "MyKaam",
  "Naukri",
  "OLX",
  "Parallel Desk",
  "Pocket Jobs",
  "Placement India",
  "Quikr",
  "Rozgaar Bazaar",
  "Shine",
  "Simply Hired",
  "Times Job",
  "Workex",
  "Workindia",
  "Others"
];

const documentTypes = [
  {
    value: "CERTIFICATE_OF_INCORPORATION",
    label: "Certificate Of Incorporation"
  },
  {
    value: "BUSINESS_NAME_REGISTRATION_CERTIFICATE",
    label: "Business Name Registration Certificate"
  },
  {
    value: "GST_REGISTRATION_CERTIFICATE",
    label: "GST Registration Certificate"
  },
  { value: "VISITING_CARD", label: "Visiting Card" },
  { value: "GOVT_ID_CARD", label: "Goverment Id Card" },
  { value: "OFFICE_ID_CARD", label: "Office Id Card" },
  { value: "SHOP_IMAGE", label: "Shop Image" }
];

const documentStatus = [
  {
    value: "under_review",
    label: "Under Review"
  },
  {
    value: "verified",
    label: "Verified"
  },
  {
    value: "rejected_2",
    label: "Rejected - Personal Doc"
  },
  {
    value: "rejected_6",
    label: "Rejected - Other Company Doc"
  },
  {
    value: "rejected_7",
    label: "Rejected - Expired or Suspended Doc"
  },
  {
    value: "rejected_3",
    label: "Rejected - Unacceptable Doc"
  },
  {
    value: "rejected_8",
    label: "Rejected - Unreadable Doc"
  },
  {
    value: "rejected_9",
    label: "Rejected - Blacklisted Recruiters"
  },
  {
    value: "rejected_5",
    label: "Rejected - Other"
  }
];

const authenticStatus = [
  { label: "Valid", value: 1 },
  { label: "Invalid", value: 0 }
];

const classfiedReasons = [
  { label: "Actual job does not exist", value: "Actual job does not exist" },
  {
    label: "Information provided is incorrect",
    value: "Information provided is incorrect"
  },
  {
    label: "Job Expired",
    value: "Job Expired"
  },
  {
    label: "JS reported by mistake",
    value: "JS reported by mistake"
  },
  {
    label: "Recruiter not receiving calls",
    value: "Recruiter not receiving calls"
  },
  {
    label: "Recruiter asking for money",
    value: "Recruiter asking for money"
  },
  {
    label: "Recruiter confirmed job is closed",
    value: "Recruiter confirmed job is closed"
  },
  {
    label: "Recruiter confirmed job is not closed",
    value: "Recruiter confirmed job is not closed"
  },
  {
    label: "Unethical behavior by recruiter",
    value: "Unethical behavior by recruiter"
  },
  {
    label: "Call not getting connected",
    value: "Call not getting connected"
  },
  {
    label: "Others",
    value: "Others"
  }
];

const reportedCallStatus = [
  { label: "New", value: "New", isDisabled: false },
  { label: "In progress", value: "In progress", isDisabled: false },
  { label: "JS - NC1", value: "NC1", isDisabled: false },
  { label: "JS - NC2", value: "NC2", isDisabled: false },
  { label: "Recruiter – NC1", value: "Recruiter – NC1", isDisabled: false },
  { label: "Recruiter - NC2", value: "Recruiter - NC2", isDisabled: false },
  { label: "Resolved", value: "Resolved", isDisabled: false },
  { label: "Auto Resolved", value: "Auto_Resolved", isDisabled: true },
  { label: "Auto Closed", value: "Auto_Closed", isDisabled: true }
];

const reportedReason = [
  {
    label: "Recruiter asked for money",
    value: "Recruiter asked for money",
    isPotential: true
  },
  {
    label: "Potential Ask for money",
    value: "Potential Ask for money",
    isPotential: true
  },
  { label: "HR Misbehaved", value: "HR Misbehaved", isPotential: true },
  {
    label: "Incorrect Job Information",
    value: "Incorrect Job Information",
    isPotential: false
  },
  {
    label: "No Response from HR",
    value: "No Response from HR",
    isPotential: false
  },
  {
    label: "Vacancy is full/Job closed",
    value: "Vacancy is full/Job closed",
    isPotential: false
  },
  { label: "Other", value: "Other", isPotential: false }
];

const entityDocStatus = ["Not required", "Pending", "Approved", "Rejected"];

const entityDocStatusNew = [
  { id: "Not required", label: "Not required" },
  { id: "Pending", label: "Pending" },
  { id: "Approved", label: "Approved" },
  { id: "Rejected", label: "Rejected" }
];

const recruiterStatus_Old = [
  { label: "New", value: "NEW" },
  { label: "Email Sent", value: "EMAIL_SENT" },
  { label: "Not connected 1", value: "NOT_CONNECTED_1" },
  { label: "Rejected – Not connected 2", value: "REJECTED_NOT_CONNECTED_2" },
  { label: "Document to be uploaded", value: "DOCUMENT_TO_BE_UPLOADED" },
  { label: "Verified", value: "VERIFIED" },
  { label: "Not Verified", value: "NOT_VERIFIED" },
  {
    label: "Auto Rejected – Doc Not Uploaded",
    value: "AUTO_REJECTED_DOC_NOT_UPLOADED"
  }
];

const recruiterStatus = [
  { label: "New", value: "NEW" },
  { label: "Verified", value: "VERIFIED" },
  { label: "Not Verified", value: "NOT_VERIFIED" },
  {
    label: "Auto Rejected",
    value: "AUTO_REJECTED"
  }
];

const recruiterStatusVirtual = [
  { label: "New", value: "NEW" },
  { label: "Verified Repeat", value: "VERIFIED_REPEAT" },
  { label: "Verified Non Repeat", value: "VERIFIED_NON_REPEAT" },
  {
    label: "Rejected",
    value: "REJECTED"
  }
];

const recruiterCallingStatus = [
  { label: "New", value: "NEW", isDisabled: false },
  {
    label: "Attempt 1 – Connected",
    value: "ATTEMPT_CONNECTED_1",
    isDisabled: false
  },
  {
    label: "Attempt 1 – Not Connected",
    value: "ATTEMPT_NOT_CONNECTED_1",
    isDisabled: false
  },
  {
    label: "Attempt 2 – Connected",
    value: "ATTEMPT_CONNECTED_2",
    isDisabled: false
  },
  {
    label: "Attempt 2 – Not Connected",
    value: "ATTEMPT_NOT_CONNECTED_2",
    isDisabled: false
  },
  {
    label: "Attempt 3 – Connected",
    value: "ATTEMPT_CONNECTED_3",
    isDisabled: false
  },
  {
    label: "Attempt 3 – Not Connected",
    value: "ATTEMPT_NOT_CONNECTED_3",
    isDisabled: false
  }
];

const CallingAttempt = [
  { label: "Connected", id: "ATTEMPT_CONNECTED" },
  { label: "Not Connected", id: "ATTEMPT_NOT_CONNECTED" }
];

const recruiterBucket = [
  { label: "Denied", value: "DENIED" },
  { label: "Job Seeker", value: "JOB_SEEKER" },
  { label: "Black list", value: "BLACKLIST" },
  {
    label: "Gray list",
    value: "GRAYLIST"
  }
];

const companyDocumentStatus = [
  {
    id: "under_review",
    value: "under_review",
    label: "Under Review"
  },
  {
    id: "verified",
    value: "verified",
    label: "Verified"
  },
  {
    id: "rejected",
    value: "rejected",
    label: "Rejected"
  }
];

const jobApprovalStatus = [
  {
    id: "VERIFIED",
    label: "Verified",
    isDisabled: false
  },
  {
    id: "PENDING",
    label: "Pending",
    isDisabled: true
  },
  {
    id: "REJECTED",
    label: "Rejected",
    isDisabled: false
  }
];

const jobCallingStatus = [
  { label: "Not Attempted", value: "NEW" },
  { label: "Attempt 1 – Connected", value: "ATTEMPT_CONNECTED_1" },
  { label: "Attempt 1 – Not Connected", value: "ATTEMPT_NOT_CONNECTED_1" },
  { label: "Attempt 2 – Connected", value: "ATTEMPT_CONNECTED_2" },
  { label: "Attempt 2 – Not Connected", value: "ATTEMPT_NOT_CONNECTED_2" }
];

const jobLiveStatus = [
  { id: "GO_LIVE", label: "Make Job Live" },
  { id: "READY_FOR_QA", label: "Send for QA" }
];
const adminPannelTabsList = [
  {
    title: "Unverified",
    id: "UNVERIFIED",
    is_disabled: false,
    newCity: true,
    isSubTab: false
  },
  {
    title: "Crawled",
    id: "CRAWLED",
    is_disabled: false,
    newCity: true,
    isSubTab: false
  },
  {
    title: "Verified",
    id: "VERIFIED",
    is_disabled: false,
    newCity: true,
    isSubTab: true,
    subTab: [
      { label: "Verify", id: "VERIFIED" },
      { label: "Suspicious", id: "SUSPECTED" }
    ]
  },
  {
    title: "Reported",
    id: "REPORTED",
    is_disabled: false,
    newCity: false,
    isSubTab: false
  },
  {
    title: "CAT",
    id: "CAT",
    is_disabled: false,
    newCity: false,
    isSubTab: false
  }
];

const expList = [
  { value: 0, label: "0 month" },
  { value: 6, label: "6 months" },
  { value: 12, label: "1 year" },
  { value: 24, label: "2 years" },
  { value: 36, label: "3 years" },
  { value: 48, label: "4 years" },
  { value: 60, label: "5 years" },
  { value: 72, label: "6 years" },
  { value: 73, label: "6+ years" }
];

const RecOnBoardingMethod = [
  {
    value: "M1",
    label: "Method 1",
    details: [
      {
        key: "Email ID:",
        value: "Official & Verified"
      },
      {
        key: "Document:",
        value: "Not Available"
      },
      {
        key: "Next Action:",
        value: "Check Onb Score & Call"
      }
    ]
  },
  {
    value: "M2",
    label: "Method 2",
    details: [
      {
        key: "Email ID:",
        value: "Official & Verified"
      },
      {
        key: "Document:",
        value: "Available"
      },
      {
        key: "Next Action:",
        value: "Check Onboarding Score"
      }
    ]
  },
  {
    value: "M3",
    label: "Method 3",
    details: [
      {
        key: "Email ID:",
        value: "Personal & Verified"
      },
      {
        key: "Document:",
        value: "Primary Document"
      },
      {
        key: "Next Action:",
        value: "Check Onboarding Score"
      }
    ]
  },
  {
    value: "OTHER",
    label: "Old Method",
    details: [
      {
        key: "Next Action:",
        value: "Check Onb Score & Call"
      }
    ]
  }
];

const UnverifiedBucketList = [
  { id: "review", label: "New Document", value: "under_review" },
  { id: "nodoc", label: "No Doc New", value: "no_document" },
  { id: "verified", label: "Approved Doc", value: "verfied" },
  { id: "followup", label: "No Doc Follow Up", value: "follow_ups" },
  { id: "knowlarity", label: "Knowlarity", value: "knowlarity" },
  { id: "others", label: "Others", value: "others" },
  {
    id: "doc_company_update",
    label: "Doc/Company Update",
    value: "doc_company_update"
  },
  {
    id: "suspicious",
    label: "Suspicious Recruiters",
    value: "suspicious"
  }
];

const ncrCityList = [
  { _id: "1_LOCATION" },
  { _id: "10_LOCATION" },
  { _id: "222_LOCATION" },
  { _id: "7_LOCATION" },
  { _id: "8_LOCATION" },
  { _id: "9_LOCATION" }
];

const crawlSourceList = [
  {
    label: "Sigma",
    value: "SIGMA"
  },
  {
    label: "Bravo",
    value: "BRAVO"
  },
  {
    label: "Alpha",
    value: "ALPHA"
  },
  {
    label: "Charlie",
    value: "CHARLIE"
  },
  {
    label: "Delta",
    value: "DELTA"
  },
  {
    label: "End Game",
    value: "ENDGAME"
  },
  {
    label: "Naukri",
    value: "NAUKRI"
  }
];

const crawledLeadType = [
  {
    label: "First Time Lead",
    value: "FIRST_TIME_LEAD"
  },
  {
    label: "Retarget Not Verified Lead",
    value: "RETARGET_NOT_VERIFIED_LEAD"
  },
  {
    label: "Retarget Verified Lead",
    value: "RETARGET_VERIFIED_LEAD"
  },
  {
    label: "New Job Lead",
    value: "NEW_JOB_LEAD"
  }
];

const enterPriseLeadType = [
  {
    label: "Hot",
    value: "hot"
  },
  {
    label: "Cold",
    value: "cold"
  },
  {
    label: "Warm",
    value: "warm"
  }
];
const enterPriseLeadBucket = [
  {
    label: "New",
    value: "New"
  },
  {
    label: "Not Connected #1",
    value: 1
  },
  {
    label: "Not Connected #2",
    value: 2
  },
  {
    label: "Not Connected #3",
    value: 3
  },
  {
    label: "Not Connected #4",
    value: 4
  },
  {
    label: "Follow Up",
    value: "Followup"
  },
  {
    label: "Reconciliation",
    value: "reconciliation_bucket"
  },
  {
    label: "interview date tab",
    value: "interview_date_tab"
  }
];

const enterPriseLeadStatus = [
  {
    label: "Interested",
    id: "Interested",
    isDisabled: false
  },
  {
    label: "Not Interested",
    id: "Not_Interested",
    isDisabled: false
  },
  {
    label: "Not Answered",
    id: "Not_Answered",
    isDisabled: false
  },
  {
    label: "Follow Up",
    id: "Follow_up",
    isDisabled: false
  }
];

const leadCallingAttemtList = [
  "lead_calling_first_status",
  "lead_calling_second_status",
  "lead_calling_third_status",
  "lead_calling_fourth_status",
  "lead_calling_fifth_status"
];

const walkinCallingAttemtList = [
  "first_call_attempt",
  "second_call_attempt",
  "third_call_attempt",
  "fourth_call_status",
  "fifth_call_status"
];

const creditsValidityList = [
  {
    label: "30 days",
    value: "30"
  },
  {
    label: "3 Months",
    value: "90"
  },
  {
    label: "1 Year",
    value: "365"
  }
];
const creditsValidityList2 = [
  {
    label: "30 days",
    value: "30 day"
  },
  {
    label: "3 Months",
    value: "3 month"
  },
  {
    label: "6 Months",
    value: "6 month"
  },
  {
    label: "1 Year",
    value: "1 year"
  }
];

const creditsAdditionType = [
  {
    label: "Paid",
    value: "PAID"
  },
  {
    label: "Un Paid",
    value: "UNPAID"
  }
];
const premiumJobType = [
  {
    label: "Premium",
    value: "PREMIUM"
  },
  {
    label: "Basic",
    value: "BASIC"
  }
];
const invalidReasonsList = [
  {
    label: "Recruiter details not matching",
    value: "RECRUITER_DETAILS_NOT_MATCHING"
  },
  {
    label: "No Recruiter details provided",
    value: "NO_RECRUITER_DETAILS_PROVIDED"
  },
  { label: "JS Reported by Mistake", value: "JS_REPORTED_BY_MISTAKE" },
  {
    label: "Not a fraud report",
    value: "NOT_A_FRAUD_REPORT"
  },
  { label: "Spam Jobseeker", value: "SPAM_JOBSEEKER" },
  {
    label: "Others",
    value: "OTHERS"
  }
];

const typeOfDriverList = [
  {
    label: "Taxi Driver with travel agency",
    value: "Taxi Driver with travel agency"
  },
  { label: "Ola/Uber Driver", value: "Ola/Uber Driver" },
  { label: "3W Cargo Driver", value: "3W Cargo Driver" },
  { label: "3W Passenger Driver", value: "3W Passenger Driver" },
  { label: "School Cab Driver", value: "School Cab Driver" },
  { label: "Mini Truck Driver", value: "Mini Truck Driver" },
  { label: "Bus Driver", value: "Bus Driver" },
  { label: "Truck Driver", value: "Truck Driver" },
  { label: "2W Driver", value: "2W Driver" },
  { label: "Personal Vehicle", value: "Personal Vehicle" },
  { label: "Commercial Vehicle", value: "Commercial Vehicle" },
  { label: "Other", value: "Other" }
];

const leadPlatform = [
  { label: "App", id: "APP" },
  { label: "WhatsApp", id: "WHATSAPP" },
  { label: "Vi", id: "PARTNER-VI" },
  { label: "Web", id: "WEB" },
  { label: "CRM", id: "CRM" }
];
const lcsLeadSource = [
  { label: "Product", value: "PRODUCT" },
  { label: "Inbound", value: "INBOUND" },
  { label: "Referral", value: "REFERRAL" },
  { label: "WhatsApp", value: "WHATSAPP" }
];
const cityGroupList = [
  { label: "West Cities", value: "WEST_CITIES" },
  { label: "Blended Cities", value: "BLENDED_CITIES" },
  { label: "Top 10 Cities", value: "TOP_10_CITIES" },
  { label: "South Tamil Cities", value: "SOUTH_TAMIL" },
  { label: "South Telegu Cities", value: "SOUTH_TELUGU" },
  { label: "South Kannada Cities", value: "SOUTH_KANNADA" },
  { label: "South Malayalam Cities", value: "SOUTH_MALAYALAM" },
  { label: "Other Cities", value: "OTHER_CITIES" }
];

const recruiterCallingSubStatusConnected = [
  { label: "Will Check later", value: "CONNECTED_WILL_CHECK_LATER" },
  { label: "Job seeker", value: "CONNECTED_JOB_SEEKER" },
  { label: "Not a recruiter", value: "CONNECTED_NOT_A_RECRUITER" },
  {
    label: "Not interested in using Job Hai",
    value: "CONNECTED_NOT_INTERESTED_IN_USING_JOB_HAI"
  },
  { label: "No vacancy", value: "CONNECTED_NO_VACANCY" },
  {
    label: "Job posted by other no.",
    value: "CONNECTED_JOB_POSTED_BY_OTHER_NO"
  },
  { label: "Other role/ city", value: "CONNECTED_OTHER_ROLE_OR_CITY" },
  { label: "other language", value: "CONNECTED_OTHER_LANGUAGE" },
  { label: "Rude behavior", value: "CONNECTED_RUDE_BEHAVIOR" },
  { label: "Using other portal", value: "CONNECTED_USING_OTHER_PORTAL" },
  { label: "DND(do not disturb)", value: "CONNECTED_DO_NOT_DISTURB" },
  { label: "Follow Up", value: "CONNECTED_FOLLOW_UP" },
  { label: "Interested", value: "INTERESTED" },
  { label: "Other", value: "CONNECTED_OTHER" }
];
const recruiterCallingSubStatusNotConnected = [
  { label: "Not reachable", value: "NOT_CONNECTED_NOT_REACHABLE" },
  { label: "Switched off", value: "NOT_CONNECTED_SWITCHED_OFF" },
  { label: "Ringing", value: "NOT_CONNECTED_RINGING" },
  { label: "Disconnected", value: "NOT_CONNECTED_DISCONNECTED" },
  { label: "Other", value: "NOT_CONNECTED_OTHER" }
];
const dagBuilderSource = [
  { label: "Mysql", value: "MYSQL" },
  { label: "Clickhouse", value: "CLICKHOUSE" }
];
const blackListRecruiterReason = [
  { value: 1, label: "Suspicious Recruiter", is_clickable: 0 }
];

const restrictActionList = [
  {
    label: "Zero Free",
    value: "ZERO_FREE"
  }
];

const restrictionStatusList = [
  {
    label: "Active",
    value: "ACTIVE"
  },
  {
    label: "Reversed",
    value: "REVERSED"
  },
  {
    label: "Moved to new request",
    value: "MOVED_TO_NEW_REQUEST"
  }
];

const paymentModes = [
  { label: "Pay U", value: "PAYU" },
  {
    label: "Scanner",
    value: "SCANNER"
  },
  {
    label: "Cheque",
    value: "CHEQUE"
  },
  {
    label: "Bank transfer",
    value: "BANK_TRANSFER"
  }
];

module.exports = {
  company_type,
  jobStatus,
  working_days,
  call_allowed,
  CRM_CLEANING_STATUS,
  CRM_CALLING_STATUS,
  CRM_QA_STATUS,
  job_timing,
  job_timing_hours,
  job_timing_minutes,
  interview_type,
  job_type,
  gender,
  minimum_qualification,
  recruiter_consultant,
  qualifications,
  english_fluency,
  user_status,
  fluency_english_user,
  job_timing_minutes_new,
  shift_type,
  sourced_from,
  documentTypes,
  documentStatus,
  classfiedReasons,
  reportedCallStatus,
  authenticStatus,
  reportedReason,
  entityDocStatus,
  recruiterStatus,
  recruiterCallingStatus,
  recruiterStatus_Old,
  recruiterBucket,
  companyDocumentStatus,
  jobApprovalStatus,
  jobCallingStatus,
  jobLiveStatus,
  recruiterStatusVirtual,
  adminPannelTabsList,
  entityDocStatusNew,
  booleanOptions,
  expList,
  interviewType,
  CallingAttempt,
  RecOnBoardingMethod,
  UnverifiedBucketList,
  ncrCityList,
  crawlSourceList,
  crawledLeadType,
  enterPriseLeadType,
  enterPriseLeadBucket,
  enterPriseLeadStatus,
  leadCallingAttemtList,
  creditsValidityList,
  creditsAdditionType,
  premiumJobType,
  invalidReasonsList,
  typeOfDriverList,
  cityGroupList,
  recruiterCallingSubStatusConnected,
  recruiterCallingSubStatusNotConnected,
  walkinCallingAttemtList,
  creditsValidityList2,
  leadPlatform,
  dagBuilderSource,
  lcsLeadSource,
  blackListRecruiterReason,
  restrictActionList,
  restrictionStatusList,
  paymentModes
};
