import JobHai from "../../api/JobHai";
import { getStorageValue } from "../indexDB";
import { getErrorMessage } from "../utils";

export const getInterviewCenter = async clientId => {
  if (!clientId) return Promise.reject("Client ID is required");

  try {
    const response = await JobHai.get(
      `../metadata/crm/lcs/interview-center/${clientId}`
    );
    return response.data.data;
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg; // Throw the error message
  }
};

export const getLCSLeadMetadata = async clientId => {
  if (!clientId) return Promise.reject("Client ID is required");

  try {
    const response = await JobHai.get(`../metadata/crm/lcs/form/${clientId}`);
    return response.data.data;
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg; // Throw the error message
  }
};

export const getLcsCampaignList = async () => {
  try {
    const response = await JobHai.get("/campaign-list");
    return response.data.data;
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg; // Throw the error message
  }
};

export const getLcsRemarks = async leadId => {
  try {
    const response = await JobHai.get(`get-lead-remarks?lead_id=${leadId}`);
    return response.data.data;
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg; // Throw the error message
  }
};

export const getModeratorList = async type => {
  const url = `moderator-assignment-weightage?is_new_city_tab=false&${type}=true`;
  try {
    const response = await JobHai.get(url);
    return response.data.data;
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg; // Throw the error message
  }
};

export const getCatSectionVariableData = async () => {
  try {
    const response = await JobHai.get("../crm/cat-section-variable");
    return response.data.data;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};

export const saveCatSectionVariableData = async data => {
  try {
    const response = await JobHai.post("../crm/cat-section-variable", {
      catCohortData: {
        ...data
      }
    });
    return response;
  } catch (error) {
    let msg = getErrorMessage(error);
    throw msg;
  }
};

export const getLearnTabData = async () => {
  const url = "../metadata/learn-tab-data";
  try {
    const response = await JobHai.get(url);
    return response.data.data;
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg; // Throw the error message
  }
};

export const getCityData = async () => {
  try {
    const data = await getStorageValue("../v2/metadata/cities", "cityData");
    return data.cities?.options || [];
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg;
  }
};

export const getCrmMetaData = async () => {
  try {
    const data = await getStorageValue(
      "../metadata/v2/crm_metadata",
      "crmMetadata"
    );
    return data || {};
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg;
  }
};

export const getCrmCityData = async () => {
  try {
    const data = await getStorageValue(
      "../v2/metadata/crm-cities",
      "crmCityData"
    );
    return data.cities?.options || [];
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg;
  }
};

export const getLocality = async cityId => {
  try {
    const data = await getStorageValue(
      `../metadata/localities_distance?city_id=${cityId}`,
      cityId
    );
    return data || [];
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg;
  }
};

export const getEnterpriseModerators = async clientId => {
  const url = `get-enterprise-moderators?client_id=${clientId}`;
  try {
    const response = await JobHai.get(url);
    return response.data.data;
  } catch (error) {
    const msg = getErrorMessage(error);
    throw msg; // Throw the error message
  }
};
