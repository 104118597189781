import React, { useEffect, useMemo, useState } from "react";
import { withStyles } from "@material-ui/styles";
import cx from "classnames";
import NumericInput from "../NumericInput";
import LoadingSpinner from "../LoadingSpinner";
import SkeletonLoader from "../SkeletonLoader";
import { styles, getData, postData } from "./helper";

const STATES = {
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  ERROR: "ERROR",
  PRELIMINARY: "PRELIMINARY",
  FINAL: "FINAL",
  SUCCESS: "SUCCESS"
};

function JobPostingCount({ classes, recId, leadId }) {
  const [dataState, setDataState] = useState();
  const [data, setData] = useState();
  const [currentState, setCurrentState] = useState(STATES.PRELIMINARY);
  const [inputVal, setInputVal] = useState("");
  const [postingState, setPostingState] = useState(STATES.FETCHED);

  useEffect(() => {
    if (inputVal) setPostingState(STATES.FETCHED);
  }, [inputVal]);

  useEffect(() => {
    setDataState(STATES.FETCHING);

    if (recId) {
      getData(recId)
        .then(response => {
          setDataState(STATES.FETCHED);
          setData(response);
        })
        .catch(() => {
          setDataState(STATES.ERROR);
        });
    }
  }, [recId]);

  async function onSave(st) {
    if (st === STATES.PRELIMINARY) {
      setCurrentState(STATES.FINAL);
    }
    if (st === STATES.FINAL) {
      setPostingState(STATES.FETCHING);
      try {
        const res = await postData(recId, inputVal);
        setData(res);
        setPostingState(STATES.SUCCESS);
        setCurrentState(STATES.PRELIMINARY);
        setInputVal("");
      } catch (error) {
        setPostingState(STATES.ERROR);

        setCurrentState(STATES.PRELIMINARY);
        setInputVal("");
      }
    }
  }
  function onCancel() {
    setCurrentState(STATES.PRELIMINARY);
    setInputVal("");
  }

  const disabled = useMemo(() => {
    return !inputVal || isNaN(Number(inputVal));
  }, [inputVal]);
  const paidJobKeyword = useMemo(
    () => (data && data.paid === 1 ? "job" : "jobs"),
    [data]
  );

  const freeJobKeyWord = useMemo(
    () => (data && data.free === 1 ? "job" : "jobs"),
    [data]
  );
  return (
    <div className={cx(classes.root)}>
      {dataState === STATES.FETCHING && (
        <div className="loading">
          <LoadingSpinner />
        </div>
      )}
      {dataState === STATES.FETCHED && (
        <>
          <div className={cx(classes.padding, classes.component1)}>
            {postingState !== STATES.FETCHING ? (
              <div className={cx("flex", "center", "bottomSpacing")}>
                <img
                  src="/images/free_job.svg"
                  alt="Add"
                  width="12"
                  height="12"
                />
                <b>
                  {data.free} Free {freeJobKeyWord}
                </b>{" "}
                <span>&nbsp; left</span>
                {data.exceptionForJobPost ? (
                  <span>&nbsp; (Zero Free Recruiter)</span>
                ) : null}
              </div>
            ) : (
              <SkeletonLoader customClass={classes.jobLoader} />
            )}
            {!leadId ? (
              <>
                {postingState !== STATES.FETCHING ? (
                  <div className={cx("flex", "center")}>
                    <img
                      src="/images/paid_job.svg"
                      alt="Add"
                      width="12"
                      height="12"
                    />
                    <b>
                      {data.paid} Paid {paidJobKeyword}
                    </b>
                    <span>&nbsp; left</span>
                    {postingState === STATES.SUCCESS ? (
                      <div className={classes.successState}>
                        <i class="fa fa-check" aria-hidden="true"></i>

                        <span>&nbsp; Saved</span>
                      </div>
                    ) : null}
                    {postingState === STATES.ERROR ? (
                      <div className={classes.errorState}>
                        <i class="fa fa-x" aria-hidden="true"></i>

                        <span>&nbsp; Unable to save</span>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <SkeletonLoader customClass={classes.jobLoader} />
                )}
              </>
            ) : null}
          </div>

          {!leadId ? (
            <>
              {data.visible && currentState === STATES.PRELIMINARY ? (
                <div
                  className={cx(
                    classes.header,
                    classes.padding,
                    classes.container2,
                    "flexRow",
                    "container2Padding",
                    "center"
                  )}
                >
                  <div className="container2Child1">
                    <b>New Jobs Allowed</b>
                    <div>Post Current Limit</div>
                  </div>
                  <div>
                    <NumericInput
                      classNames={cx(
                        "form-control",
                        "inputBox",
                        "customInputClass",
                        classes.input
                      )}
                      setParentVal={setInputVal}
                    />
                  </div>
                  <div>
                    <input
                      type="button"
                      onClick={() => onSave(STATES.PRELIMINARY)}
                      value="Save"
                      className={cx(
                        "submitBtn",
                        "btns",
                        "btn1",
                        "flex",
                        "justifyCenter"
                      )}
                      disabled={disabled}
                    />
                  </div>
                </div>
              ) : (
                data.visible &&
                currentState !== STATES.PRELIMINARY && (
                  <div
                    className={cx(
                      classes.header,
                      classes.padding,
                      classes.container2,
                      "flexRow",
                      "container2Padding",
                      "center",
                      classes.container3
                    )}
                  >
                    <div>
                      <b>{`Please confirm ${inputVal} new Job Post`}</b>
                    </div>

                    <input
                      type="button"
                      onClick={() => onSave(STATES.FINAL)}
                      value="Save"
                      className={cx(
                        "submitBtn",
                        "btns",
                        "btn1",
                        "flex",
                        "justifyCenter"
                      )}
                      disabled={postingState === STATES.FETCHING}
                    />
                    <input
                      type="button"
                      onClick={() => onCancel()}
                      value="Cancel"
                      className={cx(
                        "submitBtn",
                        "btns",
                        "btn2",
                        "flex",
                        "justifyCenter"
                      )}
                      disabled={postingState === STATES.FETCHING}
                    />
                  </div>
                )
              )}
            </>
          ) : null}
        </>
      )}
      {dataState === STATES.ERROR && (
        <div>Error Loading Job Count Data.....</div>
      )}
    </div>
  );
}

export default withStyles(styles)(React.memo(JobPostingCount));
