export const HOME = "/";

export const LOGIN = "/login";

export const JOBS = "/jobs";

export const JOB_EDIT_PAGE = "/jobs";

export const COMPANY_EDIT_PAGE = "/company";

export const LIVE_JOBS = "/live-jobs";

export const JOB_SEEKER = "/job-seeker";

export const RECUITERS = "/recruiters";

export const RECUITERS_LIST = "/recruiters/recruiter-list";

export const RECUITERS_LEADS = "/recruiters/leads";

export const RECUITERS_APPLICANT = "/recruiters/applicant";

export const AGENT_LEADS = "/agent-leads";

export const UNVERIFIED_RECRUITERS = "/recruiters/unverified";

export const ANALYTICS = "/analytics";

export const COMPLAINTS = "/complaints";
export const REPORTED_JOBS = "/complaints/reported-jobs";
export const COMPLIANCE_PROTECTION = "/complaints/compliance-protection";
export const FRAUD_KEYWORDS = "/complaints/fraud-keywords";

export const TOOLS = "/tools";
export const URL_SHORTENER = "/tools/url-shortener";
export const LEARN_TAB = "/tools/learn-tab";
export const LEARN_TAB_TAG_CATEGORY = "/tools/learn-tab/tag-category";
export const LEARN_TAB_TAG_CATEGORY_ADD = "/tools/learn-tab/tag-category-add";
export const WORKBENCH = "/tools/workbench";

export const CRAWLED_RECRUITERS = "/recruiters/crawled";

export const MANUAL_JD_AUDITING = "/jobs/manual-jd-auditing";

export const ENTERPRISE_LEADS = "/enterprise-leads";

export const ENTERPRISE_LEADS_UPDATE = "/enterprise-leads/update";

export const MODEARTOR_ASSIGNMENT = "/analytics/moderator-assignment";
export const ADMIN_PANEL = "/analytics/admin-panel";
export const MODEARTOR_ATTENDANCE = "/analytics/moderator-attendance";
export const FIX_DUPLICATE_PROFILE = "/jobs/fix-duplicate-profile";
export const RECOMMENDED_JOB = "/recruiters/recommended-job";
export const RECRUITER_JS_DETAILS = "/recruiters/js-details";
export const DASHBOARD_REPORTS = "/analytics/dashboard-reports";
export const OPERATION_TRACKING = "/analytics/operation-tracking";
export const JOB_CRAWLING = "/job-crawling";
export const CRAWLED_RECRUITER = "/crawled/recruiter";
export const TRANSACTION_HISTORY = "/recruiters/transaction-history";
export const CREDITS_RESERVED =
  "/recruiters/transaction-history?view=creditReserved";
export const CAT_ASSIGNMENT_VARIABLES =
  "/analytics/moderator-assignment/cat-variables";
export const LCS_CAMPAIGN_CREATION = "/lcs-campaign-creation";
export const CAMPAIGN_DASHBOARD = "/campaign";
export const VIEW_CAMPAIGN = "/campaign/view-campaign";
export const CREATE_CAMPAIGN = "/campaign/create-campaign";
export const EDIT_CAMPAIGN = "/campaign/edit-campaign";
export const DYNAMIC_DAG = "/tools/dynamic-dag";
export const MODERATOR_LIST = "/tools/moderator-list";
export const VIEW_AUTO_JOB_POSTING = "/auto-job-posting/view";
export const EDIT_AUTO_JOB_POSTING = "/auto-job-posting/edit";

export const LFMS = "/lfms";
export const LFMS_DETAILS = "/lfms/lead-details";
export const LFMS_COMPANIES = "/lfms/companies";
export const LFMS_SALES = "/lfms/sales";
export const LFMS_ASSIGNMENT = "/lfms/assignment";
export const LFMS_LEAD_DETAILS = "/lfms/details";
export const LFMS_ADD_LEAD = "/lfms/add-lead";
export const RESTRICT_VALUE_DELIVERY = "/recruiters/restrict-value-delivery";
export const RAISE_RESTRICT_VALUE = "/recruiters/restrict-value-delivery/raise";
export const VIEW_RESTRICT_VALUE = "/recruiters/restrict-value-delivery/view";
export const VIEW_SINGLE_REQUEST =
  "/recruiters/restrict-value-delivery/view/request";

export const LCS_IG = "/tools/lcs-ig";
