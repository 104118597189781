import React from "react";
import LoadingSpinner from "../LoadingSpinner";
import FraudRecruiter from "./FraudRecruiter";
import JobVerificationStatus from "./JobVerificationStatus";
import OnBoardingCalculater from "./OnBoardingCalculater";
import RecruiterVerificationStatus from "./RecruiterVerificationStatus";
import StaticTags from "./StaticTags";
import VerificationMethod from "./VerificationMethod";
import JobPostingCount from "../JobPostingCount";
import CreditWidget from "../CreditWidget";
import JobBoost from "../JobBoost";
import LeadVerificationStatus from "./LeadVerificationStatus";
import CustomerFeedback from "../CustomerFeedback";

const RightSideBar = props => {
  const {
    tagList,
    type,
    getFieldData,
    onClick,
    sidebarInfo,
    isLoading,
    rfdData,
    handleRfdClick,
    handleOnBoradingScore,
    relaodRfd,
    leadId
  } = props;
  const {
    onboardingMethod,
    totalLiveJobs,
    isPartOfCreditSystem = false,
    job_distinction = "",
    boost_type = null,
    jobApprovalStatus = ""
  } = sidebarInfo;
  const { jobId, recId } = tagList;
  const { isRefLoading, onboardingCalcKeys, callRecruiter } = rfdData;
  const showBoostForm = job_distinction === "Extra" || boost_type === "PAID";
  return (
    <>
      {isPartOfCreditSystem ? (
        <CreditWidget recId={recId} />
      ) : (
        <JobPostingCount recId={recId} leadId={leadId} />
      )}
      <OnBoardingCalculater
        obcData={onboardingCalcKeys}
        onboardingMethod={onboardingMethod}
        jobId={jobId}
        recId={recId}
        isRefLoading={isRefLoading}
        handleOnBoradingScore={handleOnBoradingScore}
        totalLiveJobs={totalLiveJobs}
        callRecruiter={callRecruiter}
        relaodRfd={relaodRfd}
      />
      {onboardingMethod ? (
        <VerificationMethod onboardingMethod={onboardingMethod} />
      ) : null}
      <FraudRecruiter rfdData={rfdData} handleRfdClick={handleRfdClick} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {leadId ? (
            <LeadVerificationStatus
              sidebarInfo={sidebarInfo}
              getFieldData={getFieldData}
              onClick={onClick}
            />
          ) : type === "company" ? (
            <RecruiterVerificationStatus
              sidebarInfo={sidebarInfo}
              getFieldData={getFieldData}
              onClick={onClick}
            />
          ) : (
            <JobVerificationStatus
              sidebarInfo={sidebarInfo}
              getFieldData={getFieldData}
              onClick={onClick}
            />
          )}
          <StaticTags tagList={tagList} type={type} leadId={leadId} />
        </>
      )}
      {type === "job" ? (
        <>
          {showBoostForm ? <JobBoost jobId={jobId} /> : null}
          {jobApprovalStatus === "VERIFIED" ? (
            <CustomerFeedback jobId={jobId} recId={recId} />
          ) : null}
        </>
      ) : null}
    </>
  );
};
export default RightSideBar;
