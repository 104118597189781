import React from "react";
import moment from "moment";
import { RECUITERS_APPLICANT } from "../../routes/RouteConstants";
import MetaData from "../../constants/MetaData";

const StaticTags = props => {
  const { tagList, type, leadId } = props;
  const {
    jobId,
    recId,
    companyId,
    moderatorName = "",
    updatedAt,
    createdAt,
    source = "",
    isJobAutoLive = false,
    agent_name,
    agent_phone,
    totalLiveJobs,
    totalPendingJobs,
    jobLiveDate,
    madePremium,
    applicationCount = 0,
    recoCount = 0,
    jobPostedBy = false
  } = tagList;
  const findCrawlSource = MetaData.crawlSourceList.filter(
    item => item.value === source
  );
  const sourceData = findCrawlSource.length ? findCrawlSource[0].label : source;
  return (
    <div className="staticLabel">
      {moderatorName ? (
        <div className="item">Assigned to: {moderatorName}</div>
      ) : null}

      <div className="item">
        <a
          href={`${RECUITERS_APPLICANT}/${recId}`}
          title={`View Applicant`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Recruiter ID: {recId}
          <img
            src="/images/arrow-right.svg"
            alt="arrow"
            width="10"
            height="10"
          />
        </a>
      </div>
      <div className="item">Source: {sourceData}</div>
      {type === "job" ? (
        <>
          {!leadId ? <div className="item">Job ID: {jobId}</div> : null}
          <div className="item">{totalLiveJobs} Live Jobs</div>
          <div className="item">
            <a
              href={`${RECUITERS_APPLICANT}/${recId}?type=PENDING`}
              title={`View Pending`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {totalPendingJobs} Pending Jobs
            </a>
          </div>
          <div className="item">
            Posted On: {moment(createdAt).format("DD/MM/YYYY")}
          </div>
          {jobPostedBy ? (
            <div className="item autolive">Job is Auto-Job posted</div>
          ) : null}
          {isJobAutoLive ? (
            <div className="item autolive">Auto Live</div>
          ) : null}
          {source === "FIELD_AGENT" ? (
            <div className="item">{`Agent: ${agent_name} @ ${agent_phone}`}</div>
          ) : null}
          {jobLiveDate ? (
            <div className="item">
              Live Date: {moment(jobLiveDate).format("DD/MM/YYYY")}
            </div>
          ) : null}
          {madePremium ? (
            <div className="item">
              Made premium: {moment(madePremium).format("DD/MM/YYYY")}
            </div>
          ) : null}
          <div className="item">Applications: {applicationCount}</div>
          <div className="item">Reco: {recoCount}</div>
        </>
      ) : (
        <>
          <div className="item">Company ID: {companyId}</div>
          <div className="item">
            Created On: {moment(createdAt).format("DD/MM/YYYY")}
          </div>
          <div className="item">
            Updated On: {moment(updatedAt).format("DD/MM/YYYY")}
          </div>
        </>
      )}
    </div>
  );
};

export default StaticTags;
