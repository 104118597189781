import React, { useState, useEffect } from "react";
import DocumentTitle from "react-document-title";
import JobHai from "../../../api/JobHai";
import PopupMessage from "../../PopupMessage";
import LoadingSpinner from "../../LoadingSpinner";
import ModeratorAssignmentTabs from "./ModeratorAssignmentTabs";
import ModeratorList from "./ModeratorList";
import {
  checkPermission,
  getErrorMessage,
  getValueFromURL
} from "../../../services/utils";
import PageTabs from "../../Common/PageTabs";
import {
  ANALYTICS,
  ENTERPRISE_LEADS,
  MODEARTOR_ASSIGNMENT
} from "../../../routes/RouteConstants";
import NoAccess from "../../Common/NoAccess";
import HeadingRow from "../../Common/HeadingRow";
import LcsClients from "../../Common/LcsClients";
import CatSubTabs from "./CatSubTabs";
import {
  getEnterpriseModerators,
  getInterviewCenter
} from "../../../services/metadataApiServices";
import ModeratorListCAT from "./ModeratorListCAT";
import DateRangeFilter from "../../Common/DateRangeFilter";
import { MAX_ASSIGN_VOLUME } from "../../../services/constant";
import VerifiedModeratorList from "./VerifiedModeratorList";

const ModeratorAssignment = () => {
  const isNewCity = getValueFromURL("isNewCity") === "true";
  const pageType = getValueFromURL("pageType");
  const showLeadsAdmin = checkPermission("EnterpriseLeadsAdmin");
  const isLCS = showLeadsAdmin && pageType === "LEAD";
  const showAssignment = checkPermission("Moderator Assignment");

  const catAdmin = checkPermission("CAT Assignment");
  const isAccess = showAssignment || isLCS || catAdmin;
  const isOnlyCatAdmin = !showAssignment && catAdmin;
  const isAssignmentTab = showAssignment || catAdmin;
  const clientId = getValueFromURL("client");
  let defaultType = "UNVERIFIED";
  if (isLCS) defaultType = "LEAD";
  else if (isOnlyCatAdmin || pageType) defaultType = pageType;
  else defaultType = "UNVERIFIED";

  const [msgData, setMsgData] = useState({
    msg: "",
    showMsg: false,
    msgType: "error"
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAssignmentStarted, setIsAssignmentStarted] = useState({
    value: false,
    isAssgStartedMsg: ""
  });
  const [type, setType] = useState(defaultType);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [moderatorList, setModeratorList] = useState([]);
  const activeSubTab =
    isOnlyCatAdmin || pageType === "CAT" ? "LEAD_ASSIGNMENT" : "";
  const [interviewCenterList, setInterviewCenterList] = useState([]);
  const [totalLeadsMsg, setTotalLeadsMsg] = useState("");
  const [enterpriseModerators, setEnterpriseModerators] = useState([]);
  const [showMore, setShowMore] = useState(isLCS);

  const today = new Date();
  const minStartSelectedDate = new Date(today);
  minStartSelectedDate.setDate(today.getDate() - 1);

  const [dateRange, setDateRange] = useState({
    fromDate: minStartSelectedDate,
    toDate: today
  });

  const isCAT = type === "CAT";
  const isVerified = pageType === "VERIFIED";
  useEffect(() => {
    const fetchData = async () => {
      if (isAccess) {
        try {
          setIsLoading(true);
          await getModerator();
          if (isLCS) await getInterviewCenterList();
          if (isCAT) await getAssignmentStatus();
        } catch (error) {
          setErrorMessage(getErrorMessage(error));
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [isAccess]);

  const getModerator = async () => {
    let url = `moderator-assignment-weightage`;

    if (isCAT) {
      url += `?is_cat_tab=true`;
    } else if (type === "CRAWLED") {
      url += `?is_crawled=true`;
    } else if (type === "LEAD") {
      url += `?is_lead=true`;
    } else if (type === "REPORTED") {
      url += `?is_reported=true`;
    } else {
      url += `?is_verified=${isVerified}`;
    }

    if (isLCS) {
      setShowMore(true);
      url = `moderator-assignment-weightage?is_lead=true`;
      if (clientId) url += `&client_id=${clientId}`;
    }

    url += `&is_new_city_tab=${isNewCity}`;

    setIsButtonDisabled(true);
    setErrorMessage("");

    try {
      const response = await JobHai.get(url);
      const data = response.data.data || [];
      setModeratorList(data);
      setMsgData({ msg: "", showMsg: false, msgType: "" });
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const getAssignmentStatus = async () => {
    const url = "cat-is-assignment-inprogress";
    try {
      const response = await JobHai.get(url);
      const { isCrawlingInProgress } = response.data.data;

      if (isCrawlingInProgress) {
        setIsAssignmentStarted({
          value: true,
          isAssgStartedMsg:
            "Assignment was previously started and it is in progress!!"
        });
        setMsgData({ msg: "", showMsg: false, msgType: "" });
      }
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  const getInterviewCenterList = () => {
    if (clientId) {
      getInterviewCenter(clientId)
        .then(interviewCenterList => {
          setInterviewCenterList(interviewCenterList);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleChange = (id, value, name) => {
    let index = moderatorList.findIndex(item => item.moderator_id === id);

    const updatedModeratorList = [...moderatorList];
    const moderatorToUpdate = updatedModeratorList[index];
    // Helper function to determine if is_active should be true or false
    const updateIsActive = () => {
      moderatorToUpdate.is_active =
        moderatorToUpdate.verified_weightage > 0 ||
        moderatorToUpdate.suspected_weightage > 0;
    };

    switch (name) {
      case "weightage":
        moderatorToUpdate.is_active = value > 0;
        moderatorToUpdate[name] = Number(value);
        break;
      case "verified_weightage":
        moderatorToUpdate[name] = Number(value);
        updateIsActive();
        break;
      case "suspected_weightage":
        moderatorToUpdate[name] = Number(value);
        updateIsActive();
        break;
      case "max_limit_to_assign_data":
        moderatorToUpdate[name] = Number(value);
        break;
      case "is_active":
        if (type !== "LEAD" && !value) {
          moderatorToUpdate.weightage = 0;
        }
        moderatorToUpdate[name] = value;
        break;
      default:
        moderatorToUpdate[name] = value;
        break;
    }

    // Update the moderator list and state
    setModeratorList(updatedModeratorList);
    setErrorMessage("");
    handleValidation();

    setIsAssignmentStarted({
      value: true,
      isAssgStartedMsg: ""
    });
  };

  const handleValidation = () => {
    const totalWeightage = moderatorList.reduce(
      (n, { weightage }) => n + weightage,
      0
    );
    const totalVerifiedWeightage = moderatorList.reduce(
      (n, { verified_weightage }) => n + verified_weightage,
      0
    );
    const totalSuspectedWeightage = moderatorList.reduce(
      (n, { suspected_weightage }) => n + suspected_weightage,
      0
    );
    if (isCAT) {
      const isAssignVolume = moderatorList.some(
        item =>
          !item.max_limit_to_assign_data ||
          item.max_limit_to_assign_data > MAX_ASSIGN_VOLUME
      );
      const isCityGroups = moderatorList.some(item => !item.city_group);

      if (isCityGroups) {
        setIsButtonDisabled(true);
        setErrorMessage("Please enter a valid value of city groups");
      } else if (isAssignVolume) {
        setIsButtonDisabled(true);
        setErrorMessage("Please enter a valid assign volume");
      } else {
        setIsButtonDisabled(false);
        setErrorMessage("");
      }
    } else if (isVerified) {
      if (totalVerifiedWeightage !== 100) {
        setErrorMessage("Total verify weightage is not 100");
        setIsButtonDisabled(true);
      } else if (totalSuspectedWeightage !== 100) {
        setErrorMessage("Total suspicious weightage is not 100");
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
        setErrorMessage("");
      }
    } else if (totalWeightage !== 100 && type !== "LEAD" && !isVerified) {
      setErrorMessage("Total weightage is not 100");
      setIsButtonDisabled(true);
    } else {
      setErrorMessage("");
      setIsButtonDisabled(false);
    }
  };

  const showErrorAndDisableButton = errorMessage => {
    setIsButtonDisabled(true);
    setErrorMessage(errorMessage);
  };

  const handleSave = async () => {
    const isLead = type === "LEAD";
    const getTotal = (list, condition) => list.filter(condition).length;

    const activeList = moderatorList.filter(
      item => item.is_active && !item.is_absent
    );
    const totalAbsent = getTotal(moderatorList, item => item.is_absent);
    const totalInActive = getTotal(moderatorList, item => !item.is_active);
    const totalCity = getTotal(moderatorList, item => item.city_ids);
    const totalCenter = getTotal(moderatorList, item => item.interview_centers);
    const isSuspectedError = !!moderatorList.filter(item => {
      if (item.suspected_weightage) {
        if (!item.is_active) {
          return item;
        }
      }
    }).length;
    const isVerifiedError = !!moderatorList.filter(item => {
      if (item.verified_weightage) {
        if (!item.is_active) {
          return item;
        }
      }
    }).length;
    setSuccessMsg("");
    setErrorMessage("");
    if (isVerified && isSuspectedError) {
      showErrorAndDisableButton(
        "Set moderator active for all suspicious weightage"
      );
      return;
    }
    if (isVerified && isVerifiedError) {
      showErrorAndDisableButton(
        "Set moderator active for all verified weightage"
      );
      return;
    }

    if (isLead && !getValueFromURL("client")) {
      showErrorAndDisableButton("Please select client");
      return;
    }

    if (totalAbsent === moderatorList.length) {
      showErrorAndDisableButton("Please set at least one moderator present");
      return;
    }
    if (totalInActive === moderatorList.length) {
      showErrorAndDisableButton("Please set at least one moderator active");
      return;
    }

    if (!activeList.length) {
      showErrorAndDisableButton(
        "Please set at least one active moderator present"
      );
      return;
    }
    if (totalCity !== moderatorList.length && isLead) {
      showErrorAndDisableButton("Please select city");
      return;
    }

    if (
      totalCenter !== moderatorList.length &&
      isLead &&
      interviewCenterList.length
    ) {
      showErrorAndDisableButton("Please select interview center");
      return;
    }
    let dataObj = moderatorList;
    if (isLead) {
      const newModerator = getTotal(
        moderatorList,
        item => !item.moderator_id && item.moderator_name
      );

      dataObj = moderatorList.map(item => ({
        ...item,
        city_ids: item.city_ids.map(center => center.value),
        interview_centers: item.interview_centers.map(center => center.value),
        client_id: clientId
      }));

      // Check if new moderator exists and ask for confirmation
      if (newModerator) {
        if (!window.confirm("Are you sure you want to add a new moderator?")) {
          // Return false to stop execution if user cancels
          return false;
        }
        // If confirmed, update moderator_id
        dataObj = dataObj.map(item => ({
          ...item,
          moderator_id: Number(item.moderator_id) || Number(item.moderator_name)
        }));
      }
    }
    try {
      await JobHai.post("moderator-assignment-weightage", {
        is_verified: type !== "REPORTED" ? isVerified : undefined,
        is_reported: type === "REPORTED" ? true : undefined,
        is_crawled: type === "CRAWLED" ? true : undefined,
        is_lead: isLead ? true : undefined,
        is_cat_tab: isCAT ? true : undefined,
        moderator_weights: dataObj,
        is_new_city_tab: isNewCity,
        client_id: isLead ? clientId : undefined
      });

      setSuccessMsg("Inputs saved successfully");
      setIsAssignmentStarted({
        value: false,
        isAssgStartedMsg: ""
      });

      if (type === "LEAD") {
        getModerator();
      }
      setTimeout(() => {
        setSuccessMsg("");
      }, 5000);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const onCityGroupChange = (event, id) => {
    const { value } = event.target;
    let index = moderatorList.findIndex(item => item.moderator_id === id);
    if (index !== -1) {
      const updatedModeratorList = [...moderatorList];
      const moderatorToUpdate = updatedModeratorList[index];

      moderatorToUpdate["city_group"] = value;
      setModeratorList(updatedModeratorList);
    }
    handleValidation();
    setIsAssignmentStarted({
      value: true,
      isAssgStartedMsg: ""
    });
  };

  const handleClosePopup = () => {
    setErrorMessage("");
  };

  const startAssignment = async () => {
    try {
      const response = await JobHai.post("cat-start-assignment", dateRange);
      const { isCrawlingInProgress } = response.data.data;

      if (isCrawlingInProgress) {
        setIsAssignmentStarted({
          value: true,
          isAssgStartedMsg: "Assignment started and it is in progress!!"
        });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  const onLoadLeadVolume = async ({ start_date, end_date }) => {
    try {
      const response = await JobHai.post("cat-count-lead-volume", {
        fromDate: start_date,
        toDate: end_date
      });
      const { data } = response.data;
      const { assignmentCount } = data;

      setTotalLeadsMsg("Lead volume loaded successfully");

      setTimeout(() => {
        setTotalLeadsMsg(`${assignmentCount ?? "No"} Leads Available`);
      }, 2000);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  const handleAddModerator = async () => {
    setSuccessMsg("");
    try {
      setShowMore(false);
      const response = await getEnterpriseModerators(clientId);
      if (!response.length) {
        setErrorMessage("No moderators available");
        return;
      }

      setEnterpriseModerators(response);
      const dummyData = {
        moderator_id: "",
        is_absent: 0,
        is_active: 1,
        city_ids: [],
        interview_centers: [],
        client_id: ""
      };
      setModeratorList([...moderatorList, dummyData]);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
      setShowMore(true);
    }
  };
  const pageTabs = [
    { label: "Old City", url: MODEARTOR_ASSIGNMENT, isActive: !isNewCity },
    {
      label: "New City",
      url: `${MODEARTOR_ASSIGNMENT}?isNewCity=true`,
      isActive: isNewCity
    }
  ];

  const callBackDate = (fromDate, toDate) => {
    setDateRange({ fromDate, toDate });
  };

  const goBackURL = isLCS ? ENTERPRISE_LEADS : ANALYTICS;
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <DocumentTitle title={"Job Hai CRM - Assignment"}>
      <div className="modAssignmentcontainer">
        <div className="admin-pannel">
          <div className="clr gap-20" />
          <PopupMessage data={msgData} click={handleClosePopup} />
          <HeadingRow heading="Moderator Assignment" goBackURL={goBackURL}>
            {isLCS ? (
              <LcsClients
                showAll={false}
                url={MODEARTOR_ASSIGNMENT}
                clientId={clientId}
              />
            ) : null}
            {!isLCS && !isCAT && isAssignmentTab ? (
              <PageTabs pageTabs={pageTabs} />
            ) : null}
          </HeadingRow>
          {!isLCS && isAssignmentTab ? (
            <ModeratorAssignmentTabs
              isSelected={type}
              isOnlyCatAdmin={isOnlyCatAdmin}
            />
          ) : null}
          {isCAT && (
            <>
              <CatSubTabs
                isSelected={activeSubTab}
                isAssignmentStarted={isAssignmentStarted}
              />
              <div>
                <DateRangeFilter
                  ctaText={"Load Lead Volume"}
                  ctaCallback={onLoadLeadVolume}
                  successMsg={totalLeadsMsg}
                  minStartDays={10}
                  minStartSelectedDate={minStartSelectedDate}
                  callBackDate={callBackDate}
                />
              </div>
            </>
          )}

          <div className="whitebox table-responsive">
            {isAccess ? (
              <>
                {isCAT ? (
                  <ModeratorListCAT
                    moderatorList={moderatorList}
                    onChange={handleChange}
                    onCityGroupChange={onCityGroupChange}
                  />
                ) : isVerified ? (
                  <VerifiedModeratorList
                    moderatorList={moderatorList}
                    onChange={handleChange}
                  />
                ) : (
                  <ModeratorList
                    moderatorList={moderatorList}
                    onChange={handleChange}
                    showCity={isLCS}
                    interviewCenterList={interviewCenterList}
                    handleAddModerator={handleAddModerator}
                    showAddmore={showMore}
                    pendingModeratos={enterpriseModerators}
                  />
                )}
              </>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <div className="clr gap-20"></div>
        {isAccess ? (
          <div className="float-right">
            <span className="text-danger">{errorMessage}</span>
            <span className="text-success">{successMsg}</span>
            <button
              className="btn btn-primary"
              onClick={handleSave}
              disabled={isButtonDisabled}
            >
              Save
            </button>
            {isCAT ? (
              <button
                className="btn btn-primary"
                onClick={startAssignment}
                disabled={isButtonDisabled || isAssignmentStarted.value}
              >
                Start Assignment
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    </DocumentTitle>
  );
};

export default ModeratorAssignment;
